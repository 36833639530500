/*================================================
This file contains the following styles:


General Layout
=================================================
 --Layout - Header
 --Top Header Style
 --Main Menu Style
 --Main Menu Link Normal Color
 --Main Menu Link Hover Color
 --Action Style Color Normal
 --Action Style Color Hover
 --Layout - Header Mobile
 --Layout - Footer
 --Footer Top
 --Footer Center
 --Footer Bottom
 --Layout - Offcanvas
 --Mobile Menu Bottom Area
 --Mobile Menu Contact Info
 --Layout - Modal

=================================================*/


/*Layout*/


/*****************************
* Layout - Header
*****************************/

.header-top-bg--black {
    background: #24262b;
}

.header-top-bg--white {
    background: #FFF;
    padding-top: 20px;
    padding-bottom: 20px;
}

.header-top-contact a,
.header-top-user-link a {
    line-height: 40px;
    margin-right: 40px;
    position: relative;
}

.header-top-contact a::after,
.header-top-user-link a::after {
    position: absolute;
    content: "|";
    left: calc(100% + 20px);
}

.header-top-contact a:last-child,
.header-top-user-link a:last-child {
    margin-right: 0;
}

.header-top-contact a:last-child::after,
.header-top-user-link a:last-child::after {
    display: none;
}

.header-top-contact-color--white a,
.header-top-user-link-color--white a {
    color: #FFF;
}

.header-top-contact-color--white a::after,
.header-top-user-link-color--white a::after {
    color: #FFF;
}

.header-top-contact-color--black a,
.header-top-user-link-color--black a {
    color: #24262b;
}

.header-top-contact-color--black a::after,
.header-top-user-link-color--black a::after {
    color: #24262b;
}

.header-top-contact-hover-color--green a:hover,
.header-top-user-link-hover-color--green a:hover {
    color: #047B42;
}

.header-top-contact-hover-color--aqua a:hover,
.header-top-user-link-hover-color--aqua a:hover {
    color: #66cec4;
}

.header-bottom {
    position: relative;
}

.header-bottom-color--golden {
    background: #FEF5EF;
}

.header-bottom-color--white {
    background: #FFF;
}

.header-bottom-color--black {
    background: #24262b;
}

.sticky-header.sticky.sticky-color--golden {
    background: #FEF5EF;
}

.sticky-header.sticky.sticky-color--white {
    background: #fff;
}

.sticky-header.sticky.sticky-color--black {
    background: #24262b;
}

.search_width img {
    width: 20px;
}

/* Top Header Style */
#top_header {
    background-color: #ebebeb;
    padding: 9px 0px;
}

.top_header_left p {
    font-size: 14px;
    color: #0a0d14;
}

.top_header_left a {
    color: var(--main-theme-color);
    padding-left: 5px;
}

.right_list_fix {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.right_list_fix li {
    padding-left: 26px;
}

.right_list_fix li:first-child {
    padding-left: 0;
}

.top_header_right ul li a {
    font-size: 14px;
}

.top_header_right ul li i {
    padding-right: 3px;
}

/*Main Menu Style*/

.sticky-header.sticky {
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    background: #333;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.main-menu {
    position: relative;
    width: 60%;
}

.main-menu nav>ul>li {
    position: relative;
    display: inline-block;
    padding-left: 33px;
}

.main-menu nav>ul>.has-dropdown {
    margin-right: 5px;
}

.main-menu nav>ul>.has-dropdown:last-child {
    margin-right: 0;
}

.main-menu nav>ul>.has-megaitem {
    position: static;
}

.main-menu nav>ul>li>a {
    display: block;
    line-height: 24px;
    padding: 25px 30px;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
}

.main-menu-style-4 nav>ul>li>a {
    padding: 10px 30px;
}

.sub-menu>li>a,
.mega-menu-sub>li>a {
    font-weight: 500;
    display: block;
    line-height: 35px;
    text-transform: capitalize;
    color: #777;
    position: relative;
    transition: all 0.5s ease;
}

.sub-menu>li>a:hover,
.mega-menu-sub>li>a:hover {
    transform: translateX(10px);
}

.main-menu .active {
    color: var(--main-theme-color) !important;
}

.sub-menu {
    position: absolute;
    min-width: 230px;
    padding: 25px 20px;
    background: #FFF;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    transform: perspective(600px) rotateX(-90deg);
    transform-origin: 0 0 0;
    left: 0;
    right: auto;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    z-index: 99;
    top: 100%;
    text-align: left;
}

.mega-menu {
    position: absolute;
    min-width: 100%;
    padding: 25px 30px 30px 30px;
    background: #FFF;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    transform: perspective(600px) rotateX(-90deg);
    transform-origin: 0 0 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    z-index: 99;
    top: 100%;
    text-align: left;
}

.mega-menu-inner {
    display: flex;
    justify-content: space-between;
}

.mega-menu-item-title {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-bottom: 13px;
    color: #24262b;
}

.mega-menu-sub>li {
    display: block;
}

.main-menu nav>ul>li:hover .sub-menu,
.main-menu nav>ul>li:hover .mega-menu {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateX(0deg);
}

.menu-banner>a {
    display: block;
    width: 100%;
}

.menu-banner img {
    width: 100%;
}


/*Main Menu Link Normal Color*/

.menu-color--black nav>ul>li>a {
    color: #24262b;
}

.menu-color--black .sub-menu>li>a,
.menu-color--black .mega-menu-sub>li>a {
    color: #24262b;
}

.menu-color--black .mega-menu-item-title {
    color: #24262b;
}

.menu-color--white nav>ul>li>a {
    color: #FFF;
}

.menu-color--white .sub-menu>li>a,
.menu-color--white .mega-menu-sub>li>a {
    color: #24262b;
}

.menu-color--white .mega-menu-item-title {
    color: #24262b;
}


/*Main Menu Link Hover Color*/

.menu-hover-color--golden nav>ul>li>a:hover {
    color: var(--main-theme-color);
}

.menu-hover-color--golden .sub-menu>li>a:hover,
.menu-hover-color--golden .mega-menu-sub>li>a:hover {
    color: var(--main-theme-color);
}

/* .menu-hover-color--golden .mega-menu-item-title:hover {
    color: var(--main-theme-color);
} */

.menu-hover-color--green nav>ul>li>a:hover {
    color: #047B42;
}

.menu-hover-color--green .sub-menu>li>a:hover,
.menu-hover-color--green .mega-menu-sub>li>a:hover {
    color: #047B42;
}

.menu-hover-color--green .mega-menu-item-title:hover {
    color: #047B42;
}

.menu-hover-color--pink nav>ul>li>a:hover {
    color: #ff365d;
}

.menu-hover-color--pink .sub-menu>li>a:hover,
.menu-hover-color--pink .mega-menu-sub>li>a:hover {
    color: #ff365d;
}

.menu-hover-color--pink .mega-menu-item-title:hover {
    color: #ff365d;
}

.menu-hover-color--aqua nav>ul>li>a:hover {
    color: #66cec4;
}

.menu-hover-color--aqua .sub-menu>li>a:hover,
.menu-hover-color--aqua .mega-menu-sub>li>a:hover {
    color: #66cec4;
}

.menu-hover-color--aqua .mega-menu-item-title:hover {
    color: #66cec4;
}

.header-logo {
    width: 18%;
    overflow: hidden;
    margin-bottom: 10px;
    /* padding-top: 10px; */
}

.header-logo .logo img {
    width: 100%;

    height: auto;
}

.header-action-link {
    display: flex;
    align-items: center;
}

.header-action-link li {
    margin-right: 20px;
}

.header-action-link li:last-child {
    margin-right: 0;
}

.header-action-link li a {
    font-size: 21px;
    position: relative;
    letter-spacing: -6px;
}

.header-action-link li a span {
    display: inline-block;
    font-size: 11px;
    background: darkgoldenrod;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0;
    font-weight: 600;
}

.offside-about {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    letter-spacing: normal !important;
    display: block;
    border-radius: 50%;
}

.offcanvas-close img {
    height: 18px;
}


/*Action Style Color Normal*/

.action-color--black.header-action-link li a {
    color: #24262b;
}

.action-color--black .offside-about {
    background: rgba(242, 232, 231, 0.5);
}

.action-color--white.header-action-link li a {
    color: #FFF;
}

.action-color--white .offside-about {
    background: rgba(255, 255, 255, 0.05);
}


/*Action Style Color Hover*/

.action-hover-color--golden.header-action-link li a:hover {
    color: var(--main-theme-color);
}

.action-hover-color--golden.header-action-link li a span {
    background: var(--main-theme-color);
}

.action-hover-color--golden .offside-about:hover {
    background: #FFF;
}

.action-hover-color--green.header-action-link li a:hover {
    color: #047B42;
}

.action-hover-color--green.header-action-link li a span {
    background: #047B42;
}

.action-hover-color--green .offside-about:hover {
    background: rgba(255, 255, 255, 0.2);
}

.action-hover-color--pink.header-action-link li a:hover {
    color: #ff365d;
}

.action-hover-color--pink.header-action-link li a span {
    background: #ff365d;
}

.action-hover-color--pink .offside-about:hover {
    background: rgba(255, 255, 255, 0.2);
}

.action-hover-color--aqua.header-action-link li a:hover {
    color: #66cec4;
}

.action-hover-color--aqua.header-action-link li a span {
    background: #66cec4;
}

.action-hover-color--aqua .offside-about:hover {
    background: rgba(255, 255, 255, 0.2);
}

.seperate-sticky-bar {
    display: none;
}

.seperate-sticky-bar.sticky {
    display: block;
}

.inner-search {
    right: -15px;
    width: 330px;
    background: #FFF;
    display: none;
}

.inner-search input[type=search] {
    width: 100%;
    padding: 15px 50px 15px 10px;
    border: 1px solid #f0f0f0;
    font-size: 14px;
}

.inner-search button {
    right: 0;
    background: aliceblue;
    padding: 10px 14px;
    top: 50%;
    transform: translateY(-50%);
}

.search-click {
    cursor: pointer;
}


/*****************************
* Layout - Header Mobile
*****************************/

.mobile-menu-logo li {
    margin-right: 15px;
}

.mobile-menu-logo li:last-child {
    margin-right: 0;
}

.mobile-menu-logo {
    display: flex;
    align-items: center;
}

.mobile-menu-logo .logo {
    width: 100px;
    overflow: hidden;
}

.mobile-menu-logo .logo img {
    width: 100%;
    height: auto;
}

.mobile-header {
    padding: 15px 0;
}

.offside-menu {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    letter-spacing: normal !important;
    display: block;
    border-radius: 50%;
    background: rgba(242, 232, 231, 0.5);
}

.offside-menu-color--black {
    color: #FFF;
    background: rgba(255, 255, 255, 0.05);
}

.offside-menu-hover-color--golden:hover {
    color: var(--main-theme-color);
}

.offside-menu-hover-color--green:hover {
    color: #047B42;
}

.offside-menu-hover-color--pink:hover {
    color: #ff365d;
}

.offside-menu-hover-color--aqua:hover {
    color: #66cec4;
}

.mobile-header-bg-color--golden {
    background: #FEF5EF;
}

.mobile-header-bg-color--white {
    background: #FFF;
}

.mobile-header-bg-color--black {
    background: #24262b;
}


/*****************************
* Layout - Footer
*****************************/

.footer-bg {
    background: #24262b;
    padding-top: 60px;
}

@media (min-width: 768px) {
    .footer-bg {
        padding-top: 80px;
    }
}

@media (min-width: 992px) {
    .footer-bg {
        padding-top: 100px;
    }
}


/*Footer Top*/

.footer-top .footer-widget-single-item .title {
    color: #FFF;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 23px;
    text-transform: uppercase;
}

.footer-top .footer-widget-single-item .title::after {
    content: "";
    width: 50px;
    height: 2px;
    left: 0;
    bottom: 0;
    position: absolute;
}

.footer-top .footer-widget-single-item .footer-nav li {
    margin-bottom: 8px;
    display: block;
}

.footer-top .footer-widget-single-item .footer-nav li:last-child {
    margin-bottom: 0;
}

.footer-top .footer-widget-single-item .footer-nav li a {
    display: inline-block;
    color: #9b9b97;
    transition: all 0.5s ease;
}

.footer-top .footer-widget-single-item .footer-nav li a:hover {
    color: #FFF;
    transform: translateX(10px);
}

.footer-top .footer-widget-color--golden .title::after {
    background: var(--main-theme-color);
}

.footer-top .footer-widget-color--green .title::after {
    background: #047B42;
}

.footer-top .footer-widget-color--aqua .title::after {
    background: #66cec4;
}

.footer-top .footer-widget-color--pink .title::after {
    background: #ff365d;
}


/*Footer Center*/

.footer-center {
    margin-top: 40px;
    padding: 60px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

@media (min-width: 768px) {
    .footer-center {
        margin-top: 55px;
    }
}

.footer-social .title {
    color: #FFF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 27px;
    text-transform: uppercase;
}

.footer-social-link li {
    display: inline-block;
    margin-right: 20px;
}

.footer-social-link li:last-child {
    margin-right: 0;
}

.footer-social-link li a {
    display: block;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background: #2f3239;
    color: #FFF;
    transition: all 0.5s ease;
    font-size: 16px;
}

.footer-social-link li a:hover {
    background: #FFF;
    color: #2f3239;
}

.footer-newsletter .title {
    color: #FFF;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 27px;
    text-transform: uppercase;
}

.form-fild-newsletter-single-item {
    position: relative;
}

.form-fild-newsletter-single-item input {
    width: 100%;
    border: 2px solid #9b9b97;
    border-radius: 5px;
    padding: 10px 145px 10px 20px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.5s ease;
}

.form-fild-newsletter-single-item button {
    position: absolute;
    top: 0;
    right: 0;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.5s ease;
}

.form-fild-newsletter-single-item.input-color--golden input:focus {
    border-color: var(--main-theme-color);
}

.form-fild-newsletter-single-item.input-color--golden button {
    background: var(--main-theme-color);
    color: #FFF;
}

.form-fild-newsletter-single-item.input-color--golden button:hover {
    background: #FFF;
    color: var(--main-theme-color);
}

.form-fild-newsletter-single-item.input-color--green input:focus {
    border-color: #047B42;
}

.form-fild-newsletter-single-item.input-color--green button {
    background: #047B42;
    color: #FFF;
}

.form-fild-newsletter-single-item.input-color--green button:hover {
    background: #FFF;
    color: #047B42;
}

.form-fild-newsletter-single-item.input-color--aqua input:focus {
    border-color: #66cec4;
}

.form-fild-newsletter-single-item.input-color--aqua button {
    background: #66cec4;
    color: #FFF;
}

.form-fild-newsletter-single-item.input-color--aqua button:hover {
    background: #FFF;
    color: #66cec4;
}

.form-fild-newsletter-single-item.input-color--pink input:focus {
    border-color: #ff365d;
}

.form-fild-newsletter-single-item.input-color--pink button {
    background: #ff365d;
    color: #FFF;
}

.form-fild-newsletter-single-item.input-color--pink button:hover {
    background: #FFF;
    color: #ff365d;
}


/*Footer Bottom*/

.footer-bottom {
    padding-top: 35px;
    padding-bottom: 35px;
}

.footer-copyright {
    text-transform: uppercase;
}

.footer-copyright p {
    margin-bottom: 0;
}

.footer-copyright a {
    color: #FFF;
}


/*****************************
* Layout - Offcanvas
*****************************/

.offcanvas {
    z-index: 999;
    position: fixed;
    top: 0;
    overflow: auto;
    height: 100vh;
    padding: 20px;
    transition: all 0.5s ease;
    transform: translateX(100%);
    box-shadow: none;
    background: #FFF;
    width: 300px;
    overflow-x: hidden;
}

@media (min-width: 576px) {
    .offcanvas {
        width: 450px;
    }
}

.offcanvas-leftside {
    left: 0%;
    transform: translateX(-100%);
}

.offcanvas-rightside {
    right: 0%;
    transform: translateX(100%);
}

.offcanvas.offcanvas-open {
    transform: translateX(0);
}

.offcanvas-overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
    margin-bottom: 30px;
}

.offcanvas-close {
    width: 40px;
    height: 40px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.5s ease;
}

.offcanvas-close:hover {
    color: red;
}

.offcanvas-title {
    margin-bottom: 40px;
}

.offcanvas-mobile-menu-section,
.offcanvas-mobile-about-section {
    background: #24262b !important;
}

.offcanvas-mobile-menu-section .offcanvas-close,
.offcanvas-mobile-about-section .offcanvas-close {
    color: #FFF;
}


/*Mobile Menu Bottom Area*/

.offcanvas-menu {
    margin: 20px 0;
}

.offcanvas-menu li {
    position: relative;
    padding: 7px 5px;
}

.offcanvas-menu>ul>li {
    border-bottom: 1px solid transparent;
}

.offcanvas-menu>ul>li:last-child {
    border-bottom: none;
}

.offcanvas-menu li a {
    display: block;
    color: #FFF;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
}

.offcanvas-menu li a.active {
    color: var(--main-theme-color);
}

.offcanvas-menu li a:hover {
    color: var(--main-theme-color);
}

.offcanvas-menu-expand::after {
    position: absolute;
    ;
    right: 0;
    transition: all 0.3s ease;
    color: #FFF;
    font-family: "Font Awesome 5 Free";
    content: "\f0dd";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
}

.offcanvas-menu .active>.offcanvas-menu-expand:last-child,
.sub-menu>.active .offcanvas-menu-expand:last-child {
    border-bottom: none;
}

.offcanvas-menu .active>.offcanvas-menu-expand::after,
.sub-menu>.active .offcanvas-menu-expand::after {
    color: var(--main-theme-color);
    transform: rotate(90deg);
}


/*Mobile Menu Contact Info*/

.mobile-contact-info {
    text-align: center;
    margin: 0 auto;
    padding-top: 65px;
}

.mobile-contact-info .logo {
    margin: 0 auto;
    width: 200px;
}

.mobile-contact-info .logo img {
    width: 100%;
    height: auto;
}

.mobile-contact-info .address {
    margin-top: 30px;
    color: #FFF;
}

.mobile-contact-info .address span {
    display: block;
}

.mobile-contact-info .social-link li {
    display: inline-block;
    margin-right: 20px;
}

.mobile-contact-info .social-link li:last-child {
    margin-right: 0;
}

.mobile-contact-info .social-link li a {
    display: block;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background: #2f3239;
    color: #FFF;
    transition: all 0.5s ease;
    font-size: 16px;
}

.mobile-contact-info .social-link li a:hover {
    background: #FFF;
    color: #2f3239;
}

.mobile-contact-info .user-link {
    margin-top: 30px;
}

.mobile-contact-info .user-link li {
    display: inline-block;
    margin-right: 15px;
}

.mobile-contact-info .user-link li:last-child {
    margin-right: 0;
}

.mobile-contact-info .user-link li a {
    color: #FFF;
    text-decoration: underline;
}

.mobile-contact-info .user-link li a:hover {
    color: var(--main-theme-color);
}

.offcanvas-cart-item-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.offcanvas-cart-item-single:last-child {
    margin-bottom: 0;
}

.offcanvas-cart-item-block {
    display: flex;
    align-items: center;
}

.offcanvas-cart-item-image-link {
    width: 90px;
    overflow: hidden;
    border: 1px solid #ededed;
    margin-right: 20px;
}

.offcanvas-cart-image {
    width: 100%;
}

.offcanvas-cart-item-link {
    display: block;
    font-weight: 700;
}

.offcanvas-cart-item-link:hover {
    color: #24262b;
}

.offcanvas-cart-total-price {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
}

.offcanvas-cart-total-price-text {
    font-weight: 700;
}

.offcanvas-cart-action-button-list {
    margin-bottom: 25px;
    display: block;
}

.offcanvas-cart-item-delete {
    color: red;
}

.offcanvas-cart-item-delete:hover {
    color: red;
}

.offcanvas-cart-action-button-link {
    display: block;
    background: #24262b;
    color: #FFF;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;
    border: 1px border transparent;
}

.offcanvas-cart-action-button-link:hover {
    background: #FFF;
    color: #24262b;
    border: 1px border #24262b !important;
}

.offcanvas-wishlist-item-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.offcanvas-wishlist-item-single:last-child {
    margin-bottom: 0;
}

.offcanvas-wishlist-item-block {
    display: flex;
    align-items: center;
}

.offcanvas-wishlist-item-image-link {
    width: 90px;
    overflow: hidden;
    border: 1px solid #ededed;
    margin-right: 20px;
}

.offcanvas-wishlist-image {
    width: 100%;
}

.offcanvas-wishlist-item-link {
    display: block;
    font-weight: 700;
}

.offcanvas-wishlist-item-link:hover {
    color: #24262b;
}

.offcanvas-wishlist-total-price-text {
    font-weight: 700;
}

.offcanvas-wishlist-action-button {
    margin-top: 40px;
}

.offcanvas-wishlist-action-button-list {
    margin-bottom: 25px;
    display: block;
}

.offcanvas-wishlist-item-delete {
    color: red;
}

.offcanvas-wishlist-item-delete:hover {
    color: red;
}

.offcanvas-wishlist-action-button-link {
    display: block;
    background: #24262b;
    color: #FFF;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    border: 1px border transparent;
}

.offcanvas-wishlist-action-button-link:hover {
    background: #24262b;
    color: #FFF;
    border: 1px border #24262b !important;
}

.search-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    transition: all 0.5s ease-in-out;
    transform: translate(-100%, 0);
    opacity: 0;
    z-index: 99999;
}

.search-modal.open {
    transform: translate(0px, 0px);
    opacity: 1;
}

.search-modal input[type=search] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    color: white;
    background: rgba(0, 0, 0, 0);
    font-size: 21px;
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 10px;
    outline: none;
    border-bottom: 2px solid #FFF;
    padding-bottom: 10px;
}

.search-modal input[type=search]:focus {
    border-color: var(--main-theme-color);
}

.search-modal .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
}

.search-modal .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    opacity: 1;
    width: 40px;
    background: #fff;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    font-size: 0;
}


/*****************************
* Layout - Modal
*****************************/

.modal {
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    padding-right: 0 !important;
}

.modal-dialog {
    max-width: calc(100% - 20px) !important;
    margin: 0 auto !important;
}

@media (min-width: 768px) {
    .modal-dialog {
        max-width: 730px !important;
    }
}

@media (min-width: 992px) {
    .modal-dialog {
        max-width: 870px !important;
    }
}

@media (min-width: 1200px) {
    .modal-dialog {
        min-width: 825px !important;
    }
}

.modal-body {
    padding-top: 20px;
    padding-bottom: 20px;
}

.modal-close {
    width: 40px;
    height: 40px;
    border: 1px solid #777;
    color: #777;
    margin-bottom: 20px;
    border-radius: 3px;
}

.modal-close:hover {
    color: var(--main-theme-color);
    border-color: var(--main-theme-color);
}

@media (min-width: 768px) {
    .modal-close {
        margin-bottom: 0px;
    }
}

.modal-border {
    border-left: none;
}

@media (min-width: 992px) {
    .modal-border {
        border-left: 1px solid #ededed;
    }
}

.modal-content {
    border: none;
}

.modal.fade {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
}

.modal.fade.show {
    display: block !important;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease 0s;
}

.modal-add-cart-product-img {
    text-align: center;
}

.modal-add-cart-product-title {
    margin-top: 25px;
}

@media (min-width: 768px) {
    .modal-add-cart-product-title {
        margin-top: 0px;
    }
}

.modal-add-cart-product-title,
.modal-add-cart-product-price {
    display: block;
    font-weight: 500;
}

.modal-add-cart-info {
    margin-top: 40px;
}

@media (min-width: 768px) {
    .modal-add-cart-info {
        margin-top: 0;
    }
}

.modal-add-cart-product-info,
.modal-add-cart-product-shipping-info {
    font-weight: 500;
}

.modal-add-cart-product-info li,
.modal-add-cart-product-shipping-info li {
    margin-bottom: 10px;
}

.modal-add-cart-product-info li:last-child,
.modal-add-cart-product-shipping-info li:last-child {
    margin-bottom: 0;
}

.modal-add-cart-product-info span,
.modal-add-cart-product-shipping-info span {
    font-weight: 300;
    padding-left: 5px;
}

.modal-add-cart-product-cart-buttons {
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .modal-add-cart-product-cart-buttons {
        flex-direction: row;
    }
}

.modal-add-cart-product-cart-buttons a {
    margin-top: 10px;
    margin-bottom: 10px;
    background: var(--main-theme-color);
    color: white;
    padding: 5px 10px;
    margin-right: 0px;
    text-align: center;
}

.modal-add-cart-product-cart-buttons a:last-child {
    margin-right: 0;
}

@media (min-width: 576px) {
    .modal-add-cart-product-cart-buttons a {
        margin-top: 20px;
        margin-bottom: 15px;
        margin-right: 10px;
        flex-direction: row;
    }
}

.modal-add-cart-info i {
    color: var(--main-theme-color);
    padding-right: 10px;
    font-size: 16px;
}

.modal-continue-button a {
    text-decoration: underline;
    color: var(--main-theme-color);
}

.modal-continue-button a:hover {
    text-decoration: none;
}

.modal-product-details-social .title {
    display: block;
    font-size: 16px;
    margin-bottom: 15px;
}

.modal-product-details-social ul {
    display: flex;
    flex-wrap: wrap;
}

.modal-product-details-social li {
    margin-right: 10px;
}

.modal-product-details-social li:last-child {
    margin-right: 0;
}

.modal-product-details-social li a {
    width: 40px;
    height: 40px;
    display: block;
    line-height: 40px;
    text-align: center;
    color: #777;
    font-size: 16px;
    border: 1px solid #ededed;
}

.modal-product-about-text {
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
}

.product-image-thumb {
    position: relative;
}

.product-image-thumb img {
    border: 1px solid #ededed;
}

.product-image-thumb.modal-product-image-thumb {
    margin-top: 15px;
}

.product-image-thumb.modal-product-image-thumb .slick-list {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.product-image-thumb.modal-product-image-thumb .slick-slide {
    margin-left: 7.5px;
    margin-right: 7.5px;
}

.product-image-thumb:hover .gallery-nav {
    visibility: visible;
    opacity: 1;
}

.material-scrolltop {
    z-index: 999;
    display: block;
    position: fixed;
    width: 0;
    height: 0;
    bottom: 23px;
    right: 23px;
    padding: 0;
    overflow: hidden;
    outline: none;
    border: none;
    border-radius: 2px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    cursor: hand;
    border-radius: 50%;
    background: var(--main-theme-color);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all 0.3s cubic-bezier(0.25, 0.25, 0, 1);
}

.material-scrolltop:hover {
    background-color: var(--main-theme-color);
    text-decoration: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5), 0 3px 15px rgba(0, 0, 0, 0.5);
}

.material-scrolltop::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 0;
    border-radius: 100%;
    background: #bda379;
}

.material-scrolltop:active::before {
    width: 120%;
    padding-top: 120%;
    transition: all 0.2s ease-out;
}

.material-scrolltop.reveal {
    width: 56px;
    height: 56px;
}

.material-scrolltop span {
    display: block;
    font-size: 25px;
    color: #fff;
}

.offcanvas-cart-action-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.address img {
    padding-bottom: 20px;
}

.mobile-contact-info .social-link {
    padding-top: 5px;
}