/*================================================
This file contains the style elements for objects that have something
to do with having an online store, account creation and logins:


Shop Styling
=================================================
 --Cart Page
 --Components - Table
 --Empty Cart Page
 --CheckOut Page
 --CheckOut Two Page
 --Order Tracking
 --Error Page
 --Compare Page
 --My Account Page
 --Login Page
 --invoice pages start
 --Invoice Page Two
 --LookBook Page
 --Offer Modal
 --Cart 3 Page
 --Product Details 3 Page
 --Sidebar
 --Account Info Edit Page
 --Order Completed Page
 --Vendor Dashboard Page
 --Vendor Profile
 --Setting Area
 --Add Product Page

=================================================*/


/* ---------------------------------
     Cart Page
-------------------------------------*/

/*****************************
* Components - Table
*****************************/

table {
    width: 100%;
}

.table-responsive {
    position: relative;
    z-index: 1;
}

.view {
    color: #b19361;
}

.table_desc {
    border: 1px solid #ededed;
}

.table_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
    border-right: 0;
}

.table-responsive table thead {
    background: var(--main-theme-color);
}

.table_page table thead tr th {
    color: #fff;
    border-bottom: 3px solid var(--main-theme-color);
    border-right: 1px solid #ededed;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px;
    text-align: center;
}

.table_page table tbody tr td {
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    text-align: center;
    padding: 10px;
}

.table-responsive table tbody tr td {
    border-right: 1px solid #ededed;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    min-width: 150px;
}

.order_table table tbody tr td {
    border-left: 2px solid #ededed;
}

.product_stock h6 {
    font-size: 16px;
}

.table_page table tbody tr td.product_remove {
    min-width: 100px;
    font-size: 20px;
}

.table_page table tbody tr td.product_thumb {
    max-width: 180px;
    overflow: hidden;
}

.table_page table tbody tr td.product_thumb img {
    width: 92px;
    height: 92px;
    object-fit: cover;
}

.table_page table tbody tr td.product_name {
    min-width: 180px;
}

.table_page table tbody tr td.product-price {
    min-width: 130px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

.table_page table tbody tr td.product_quantity {
    min-width: 180px;
}

.table_page table tbody tr td.product_quantity label {
    font-weight: 600;
    margin-right: 5px;
}

.table_page table tbody tr td.product_quantity input {
    width: 60px;
    height: 40px;
    padding: 0 5px 0 10px;
    background: none;
    border: 1px solid #ededed;
}

.table_page table tbody tr td.product_addcart {
    min-width: 180px;
}

.cart_submit {
    text-align: right;
    padding: 12px;
}

.coupon_code {
    border: 1px solid #ededed;
    margin-top: 60px;
}

.coupon_code h3,
.coupon_code .h3 {
    color: #ffffff;
    line-height: 36px;
    padding: 5px 15px;
    background: var(--main-theme-color);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.coupon_inner {
    padding: 10px 20px 25px;
}

.coupon_inner p {
    font-size: 13px;
    margin-bottom: 20px;
}

.coupon_inner input {
    border: 1px solid #ededed;
    min-height: 38px;
    background: none;
    padding: 0 20px;
    margin-right: 20px;
    font-size: 12px;
    color: #333;
    width: 175px;
}

.coupon_inner a {
    display: block;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    border-radius: 3px;
}

.cart_subtotal {
    display: flex;
    justify-content: space-between;
}

.cart_subtotal p {
    font-weight: 600;
    font-size: 14px;
}

.cart_subtotal p span {
    margin-right: 30px;
}

.cart_subtotal .cart_amount {
    font-size: 18px;
    font-weight: 500;
}

.checkout_btn {
    text-align: right;
}

.checkout_btn a {
    display: initial;
    font-size: 12px;
}

#cart_area_two .plus-minus-input {
    justify-content: center;
}

#cart_area_two .table_page table tbody tr td.product_quantity input {
    margin: 0 8px;
}

.coupon_inner_two {
    padding-top: 30px;
}

.coupon_inner_two p {
    font-size: 13px;
    margin-bottom: 20px;
}

.coupon_inner_two input {
    border: 1px solid #ededed;
    min-height: 38px;
    background: none;
    padding: 0 20px;
    margin-right: 20px;
    font-size: 12px;
    color: #333;
    width: 175px;
}

.coupon_inner_two a {
    display: block;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    border-radius: 3px;
}

.product_quantity input {
    text-align: center;
}

.pro-remove button {
    background: transparent;
}


/* ---------------------------------
    Empty Cart Page
-------------------------------------*/

.empaty_cart_area {
    text-align: center;
}

.empaty_cart_area img {
    width: 100%;
}

.empaty_cart_area h3 {
    padding-top: 15px;
}

.empaty_cart_area a {
    margin-top: 25px;
}


/* ---------------------------------
       CheckOut Page
-------------------------------------*/

#checkout_one .form-group label {
    font-size: 16px;
    font-weight: 600;
}

.bg-white {
    background-color: #fff;
}

.checkout-area-bg {
    padding: 30px;
    box-shadow: 0px 0px 10px 0px #00000012;
}

.check-heading {
    border-bottom: 2px solid var(--main-theme-color);
}

.check-heading h3 {
    font-weight: 700;
    padding-bottom: 10px;
}

.check-out-form {
    padding-top: 30px;
}

#checkout-area .form-group {
    margin-bottom: 20px;
}

#checkout-area .form-group label {
    font-size: 16px;
    font-weight: 600;
}

#checkout-area .form-control {
    border: 1px solid var(--main-theme-color);
    background: #fff;
    font-size: 14px;
    border-radius: 0;
}

.order_review {
    background-color: #f7f8fb;
    padding: 30px;
}

.order_table thead th {
    border-bottom-width: 1px;
    font-weight: 600;
    color: #fff;
}

.order_table tbody td {
    border: 0;
}

.order_table tfoot th {
    font-weight: 600;
}

.product-subtotal {
    font-weight: 600;
}

.payment_method {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.payment_option .custome-radio {
    margin-bottom: 10px;
}

.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
    display: none;
}

.payment_option .custome-radio .form-check-label {
    color: #292b2c;
    font-weight: 600;
}

.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
    position: relative;
    cursor: pointer;
}

.custome-radio input[type="radio"]:checked+.form-check-label::before {
    border-color: var(--main-theme-color);
}

.custome-radio .form-check-label::before {
    content: "";
    border: 1px solid #908f8f;
    height: 16px;
    width: 16px;
    display: inline-block;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 8px;
}

.custome-radio input[type="radio"]:checked+.form-check-label::after {
    opacity: 1;
}

.custome-radio input[type="radio"]+.form-check-label::after {
    content: "";
    background-color: var(--main-theme-color);
    height: 10px;
    width: 10px;
    border-radius: 100%;
    position: absolute;
    top: 8px;
    left: 3px;
    opacity: 0;
}

.payment-text {
    display: none;
    margin-top: 5px;
    margin-bottom: 0;
}

.payment_option .custome-radio:first-child .payment-text {
    display: block;
}

.payment_option .custome-radio .form-check-label {
    color: #292b2c;
    font-weight: 600;
}

.order_review {
    margin-bottom: 40px;
    box-shadow: 0px 0px 10px 0px #00000012;
}

.payment_method {
    padding-top: 30px;
}

.order_table {
    padding-top: 30px;
}

.order_review .table-responsive table thead {
    background: var(--main-theme-color);
    text-align: center;
}


/* ---------------------------------
       CheckOut Two Page
-------------------------------------*/

.user-actions {
    margin-bottom: 20px;
}

.user-actions h3,
.user-actions .h3 {
    font-size: 13px;
    font-weight: 400;
    background-color: #f7f6f7;
    padding: 15px 10px;
    border-top: 3px solid var(--main-theme-color);
    margin-bottom: 0;
}

.user-actions h3 a,
.user-actions .h3 a {
    color: var(--main-theme-color);
}

.checkout_info {
    border: 1px solid #ededed;
    margin-top: 25px;
    padding: 20px 30px;
}

.checkout_info p {
    margin-bottom: 15px;
    font-size: 14px;
}

.form_group {
    margin-bottom: 20px;
}

.form_group .btn {
    margin-right: 20px;
}

.form_group.group_3 {
    display: flex;
    align-items: center;
}

.checkout_coupon input[type=text] {
    background: none;
    border: 1px solid #ededed;
    width: 200px;
    height: 45px;
    font-size: 12px;
    padding: 0 20px;
    color: #777;
}

.checkout_coupon .btn {
    margin-left: 20px;
}

.country_option {
    width: 100%;
}

.country_option .list {
    height: 150px;
    overflow-y: scroll;
}

.order-notes {
    margin-bottom: 53px;
}

.default-form-box label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

#checkout_two .checkout_coupon input[type=text] {
    background: none;
    border: 1px solid #ededed;
    width: 200px;
    min-height: 35px;
    font-size: 12px;
    padding: 0 20px;
    color: #777;
}

#checkout_two .checkout_coupon input[type=text] {
    background: none;
    border: 1px solid #ededed;
    width: 200px;
    max-height: 36px;
    ;
    font-size: 12px;
    padding: 0 20px;
    color: #777;
}

.default-form-box .checkbox-default {
    padding-left: 20px;
    margin: 0;
}

#checkout_two .checkout_area_two h3 {
    font-size: 16px;
    line-height: 30px;
    padding: 5px 10px;
    text-transform: uppercase;
    color: #fff;
    background: var(--main-theme-color);
    font-weight: 500;
}

#checkout_two #checkout_login label span {
    padding-left: 5px;
}

.checkout_form_area form {
    padding-top: 20px;
}

.checkout_form_area form .default-form-box {
    padding-bottom: 20px;
}

.order_table table thead tr th {
    min-width: 50%;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.order_table table tbody tr td {
    min-width: 50%;
    text-align: left;
    padding: 15px 30px;
    border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr th {
    min-width: 50%;
    text-align: left;
    padding: 15px 30px;
    border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr td {
    min-width: 50%;
    text-align: left;
    padding: 15px 30px;
    border-bottom: 1px solid #ddd;
}

.input-radio span {
    font-weight: 500;
    padding-right: 10px;
}

.input-radio span input[type=radio],
.account_login_form form span input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin-right: 2px;
    position: relative;
    top: 2px;
}

.checkbox-default-more-text em {
    margin-top: 16px;
    display: block;
}

#methodCod p {
    padding-bottom: 20px;
}

.checkout_area_two .table-responsive table thead {
    background: #000;
}


/* ---------------------------------
     Order Tracking
-------------------------------------*/

.order_tracking_wrapper {
    box-shadow: 0px 0px 7px 3px #0000002b;
    padding: 20px 30px;
}

.order_tracking_wrapper p {
    text-align: center;
}

.order_tracking_wrapper h4 {
    padding-bottom: 10px;
    text-align: center;
}

.order_tracking_wrapper form {
    padding-top: 20px;
}

.order_track_button {
    text-align: center;
}


/* ---------------------------------
     Error Page
-------------------------------------*/

.erorr_wrapper {
    text-align: center;
}

.erorr_wrapper h1 {
    font-weight: 200;
    font-size: 200px;
    text-shadow: 2px 8px 5px #f7983747;
    font-style: italic;
    line-height: 165px;
}

.erorr_wrapper h3 {
    padding-bottom: 30px;
    padding-top: 30px;
}

.erorr_wrapper form input {
    border-radius: 0px;
}

.erorr_wrapper form button {
    background: #000;
    color: #fff;
    padding: 1px 20px;
}

.erorr_wrapper a {
    margin-top: 30px;
}


/* ---------------------------------
     Compare Page
-------------------------------------*/

.compare-table .table tbody tr td.first-column {
    min-width: 180px;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    line-height: 1;
}

.compare-table .table tbody tr td {
    text-align: center;
    border: none;
    padding: 25px 30px;
    vertical-align: middle;
    border-bottom: 1px solid #ededed;
    border-left: 1px solid #ededed;
}

.compare-table .table tbody tr td.product-image-title {
    min-width: 305px;
    vertical-align: bottom;
}

.compare-table .table tbody tr td.product-image-title .image {
    clear: both;
    width: 100%;
    margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
    max-width: 100%;
    width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category:hover,
.compare-table .table tbody tr td.product-image-title .title:hover {
    color: #b19361;
}

.compare-table .table tbody tr td.product-image-title .category {
    clear: both;
    color: #333;
    float: left;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 15px;
    margin-bottom: 7px;
    margin-top: 15px;
    text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
    float: left;
    clear: both;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
}

.compare-table .table tbody tr td.pro-desc p {
    text-align: left;
    margin: 0;
}

.compare-table .table tbody tr td.pro-price {
    font-size: 15px;
    font-weight: 600;
}

.compare-table .table tbody tr td.pro-color {
    font-size: 15px;
    font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
    transition: 0.3s;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 21px;
    overflow: hidden;
    display: block;
    text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.pro-stock {
    font-size: 15px;
    font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart .pro-remove button {
    border: none;
    background-color: transparent;
    padding: 0;
}

.compare-table table {
    margin-bottom: 0;
}

/* ---------------------------------
     My Account Page
-------------------------------------*/

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.dashboard_tab_button {
    margin-bottom: 50px;
}

.dashboard_tab_button li a {
    border: 1px solid #E8E8E8;
    border-bottom: none;
    color: #212121;
    font-weight: 500;
    font-size: 15px;
    display: block;
    padding: 18px 15px;
    text-transform: uppercase;
}

.dashboard_tab_button li a:last-child {
    border-bottom: 1px solid #E8E8E8;
}

.dashboard_tab_button li a i {
    font-size: 14px;
    text-align: center;
    width: 25px;
}

.dashboard_tab_button li a.active,
.dashboard_tab_button li a:hover {
    background-color: var(--main-theme-color);
    border-color: var(--main-theme-color);
    color: #fff;
}

.add_prod_button {
    position: absolute;
    right: 0;
    top: 18px;
}

.dashboard_content h4,
.dashboard_content .h4 {
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    color: #212121;
    border-bottom: 1px dashed #c0c0c0;
    padding-bottom: 10px;
    margin-bottom: 25px;
    font-weight: 500;
}

.profils_details_vendor h4 {
    border-bottom: none;
}

.profile_right h4 {
    font-weight: 600 !important;
}

.dashboard_content p a {
    color: #b19361;
    font-weight: 500;
}

.billing_address h5 {
    padding-bottom: 10px;
}

.input-radio span {
    font-weight: 500;
    padding-right: 10px;
}

.input-radio span input[type=radio],
.account_login_form form span input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin-right: 2px;
    position: relative;
    top: 2px;
}

.checkbox-default-more-text em {
    margin-top: 16px;
    display: block;
}

.account_details_form .default-form-box {
    margin-bottom: 20px;
}

.account_details_form .input-radio {
    margin-top: 20px;
}

.account_details_form .example {
    font-size: 14px;
    display: block;
}

.myaccount-content .title {
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    color: #212121;
    border-bottom: 1px dashed #E8E8E8;
    padding-bottom: 10px;
    margin-bottom: 25px;
    font-weight: 500;
}

.myaccount-content {
    border: 1px solid #E8E8E8;
    padding: 30px;
}

.myaccount-content table {
    overflow: hidden;
}

.billing_address p {
    padding-top: 10px;
}

.billing_address a {
    margin-top: 20px;
}

.img_profiles {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid var(--main-theme-color);
    overflow: hidden;
}

.fixed_icon input {
    opacity: 0;
    position: absolute;
}

/* ---------------------------------
   Login Page
-------------------------------------*/

.account_form {
    box-shadow: 0px 0px 7px 5px #0000000a;
    padding: 30px 40px;
    background: #fff;
}

.account_form h3 {
    text-align: center;
}

.account_form form {
    padding-top: 30px;
}

.account_form form .default-form-box {
    margin-bottom: 30px;
}

.login_submit {
    text-align: center;
}

.remember_area {
    padding-top: 20px;
    padding-bottom: 10px;
}


/*Added for login screen, google button*/
.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-account-header {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
}

.google-button {
  background-color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.google-button img {
  margin-right: 10px;
  width: 24px; /* You can adjust the size as needed */
}

.google-button:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

/* Media Query for Mobile responsiveness */
@media (max-width: 768px) {
  .google-button {
    padding: 8px 10px; /* Slightly smaller padding on mobile */
    font-size: 14px; /* Slightly smaller font-size on mobile */
  }
}

.logout-button {
  padding: 5px 10px;
  background-color: #ffcccc; /* Soft red color */
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 20px; /* Space from the My Account label */
}



.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator hr {
  width: 40%;
  margin: 0 10px;
}

.separator span {
  white-space: nowrap;
}

/* Flex container for buttons and links */
.flex-center {
  display: flex;
  justify-content: center;
}

/**=====================
    invoice pages start
==========================**/
.invoice-wrapper {
    background-color: white;
    -webkit-box-shadow: #e6e6e6 0px 0px 14px 3px;
    box-shadow: #e6e6e6 0px 0px 14px 3px;
}

.invoice-wrapper h2 {
    font-size: 30px;
}

.invoice-wrapper h4 {
    color: #646464;
    font-size: 18px;
    font-weight: 400;
}

.invoice-wrapper .invoice-detail h5 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 600;
}

.invoice-wrapper .invoice-detail h6 {
    margin-bottom: 0;
    font-size: 16px;
    color: #6d6d6d;
}

.invoice-wrapper .invoice-body {
    padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper .invoice-body .table th,
.invoice-wrapper .invoice-body .table td {
    border-top: none;
}

.invoice-wrapper .invoice-body .table {
    font-size: 16px;
}

.invoice-wrapper .invoice-body .table td {
    color: #646464;
}

.invoice-wrapper .invoice-body .table tbody td,
.invoice-wrapper .invoice-body .table tbody th {
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
}

.invoice-wrapper .invoice-body .table tbody th {
    font-weight: normal;
    color: #646464;
}

.invoice-wrapper .invoice-body .table tbody tr:first-child td,
.invoice-wrapper .invoice-body .table tbody tr:first-child th {
    padding-top: 22px;
}

.invoice-wrapper .invoice-body .table tbody tr:last-child td,
.invoice-wrapper .invoice-body .table tbody tr:last-child th {
    padding-bottom: 22px;
}

.invoice-wrapper .invoice-body .table tfoot {
    border-top: 1px solid #ddd;
}

.invoice-wrapper .invoice-body .table tfoot td {
    padding-top: 15px;
}

.invoice-wrapper .invoice-footer {
    padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper .authorise-sign h6 {
    margin-bottom: 0;
    font-size: 18px;
    color: black;
    font-weight: bold;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    line-height: 1;
}

.theme-invoice-1 .invoice-header {
    padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    position: relative;
}

.theme-invoice-1 .invoice-header h4 {
    font-size: 16px;
    color: #646464;
    margin-bottom: 5px;
}

.theme-invoice-1 .invoice-header .upper-icon {
    position: absolute;
    width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    top: 0;
    right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--theme-deafult);
}

.theme-invoice-1 .invoice-header .upper-icon img {
    width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-1 .invoice-header .header-content {
    margin-top: 50px;
}

.theme-invoice-1 .invoice-header .header-content h2 {
    font-size: 30px;
}

.theme-invoice-1 .invoice-header .detail-bottom {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #dddddd;
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
    color: var(--theme-deafult);
    text-transform: capitalize;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
    margin-bottom: 0;
    margin-left: 4px;
}

.theme-invoice-4 .invoice-header {
    padding: calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.theme-invoice-4 .invoice-header .background-invoice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}

.theme-invoice-4 .invoice-wrapper .invoice-body {
    padding: 0;
    z-index: 1;
    position: relative;
}

.theme-invoice-4 .top-sec {
    padding: 0 calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-4 .date-detail li h4 {
    margin-bottom: 0;
}

.theme-invoice-4 .date-detail li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.theme-invoice-4 .date-detail li span {
    font-size: 18px;
    text-transform: capitalize;
    color: var(--main-theme-color);
    margin-right: 8px;
}

.theme-invoice-4 .title-sec {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    position: relative;
}

.theme-invoice-4 .title-sec .title {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-transform: uppercase;
}

.theme-invoice-4 .table-sec {
    padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table thead {
    background-color: black;
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table thead th {
    border-bottom: 0 !important;
    padding: 18px;
    color: white;
}

.invoice-wrapper .invoice-body .table thead th {
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 16px;
    border-color: var(--main-theme-color) !important;
    border-bottom: 1px solid;
}

.theme-invoice-4 .table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: white;
    color: #646464;
}

.theme-invoice-4 .table-striped>tbody>tr:nth-of-type(even) {
    --bs-table-accent-bg: #0000000d;
    color: #212529;
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table tbody th,
.theme-invoice-4 .invoice-wrapper .invoice-body .table tbody td {
    padding: 18px 18px !important;
    font-size: 18px;
}

.theme-invoice-4 .table-sec .table-footer {
    background-color: var(--main-theme-color);
    display: inline-block;
    padding: 10px 10px 10px 10px;
    color: white;
    margin-top: 30px;
    font-size: 18px;
}

.theme-invoice-4 .table-sec .table-footer span:first-child {
    margin-right: 20px;
}

.theme-invoice-4 .table-sec .table-footer span:nth-child(2) {
    font-weight: 600;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer {
    padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    position: relative;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer .design-shape {
    position: absolute;
    top: -34px;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li i {
    margin-right: 30px;
    color: var(--main-theme-color);
    font-size: 20px;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li h4 {
    line-height: 24px;
    font-size: 16px;
}

/* --Invoice Page Two-- */
.theme-invoice-1 .invoice-header {
    padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
}

.theme-invoice-1 .invoice-header .upper-icon {
    position: absolute;
    width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    top: 0;
    right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--main-theme-color);
}

.theme-invoice-1 .invoice-header .upper-icon img {
    width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-1 .invoice-header .header-content {
    margin-top: 50px;
}

.theme-invoice-1 .invoice-header .detail-bottom {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #dddddd;
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
    color: var(--main-theme-color);
    text-transform: capitalize;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
    margin-bottom: 0;
    margin-left: 4px;
}

.invoice-wrapper .authorise-sign {
    position: absolute;
    bottom: calc(13px + (40 - 13) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
}


/*--------------------------------
         LookBook Page
-----------------------------------*/
.lookbook .lookbook-block {
    position: relative;
}

.lookbook .lookbook-block .lookbook-dot {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 29px;
    height: 29px;
    line-height: 29px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 50%;
    left: 12%;
    background-color: #ffffff;
}

.lookbook .lookbook-block .lookbook-dot:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    zoom: 1;
    background-color: transparent\9;
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.lookbook .lookbook-block .lookbook-dot span {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox {
    visibility: hidden;
    top: -98px;
    left: 150%;
    position: absolute;
    width: 130px;
    background-color: #ffffff;
    -webkit-box-shadow: -3px -3px 13px rgb(48 54 61 / 10%);
    box-shadow: -3px -3px 13px rgb(48 54 61 / 10%);
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid #ffffff;
    border-bottom: 8px solid transparent;
    top: 41%;
    left: -8px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
    margin-top: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
    padding: 5px;
    text-align: center;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
    font-weight: 700;
    font-size: 16px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    margin-bottom: 0;
    text-transform: capitalize;
    line-height: 20px;
    font-size: 14px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
    text-transform: uppercase;
    color: var(--main-theme-color);
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
}

.lookbook .lookbook-block .lookbook-dot.dot2 {
    top: 70%;
    left: 27%;
}

.lookbook .lookbook-block .lookbook-dot.dot3 {
    top: 36%;
    left: 35%;
}

.lookbook .lookbook-block .lookbook-dot.dot4 {
    top: 66%;
    left: 55%;
}

.lookbook .lookbook-block .lookbook-dot.dot5 {
    top: 23%;
    left: 38%;
}

.lookbook .lookbook-block .lookbook-dot:hover {
    background-color: var(--main-theme-color);
    -webkit-box-shadow: 0 0 0 3px rgb(255 255 255 / 50%);
    box-shadow: 0 0 0 3px rgb(255 255 255 / 50%);
    z-index: 10;
}

.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
    -webkit-animation: fadeInUp 400ms ease-in-out;
    animation: fadeInUp 400ms ease-in-out;
    visibility: visible;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.lookbook .lookbook-block .lookbook-dot:hover:before {
    transform: scale(1.3);
}

.lookbook .lookbook-block .lookbook-dot:hover span {
    color: #ffffff;
}

.img-width img {
    width: 100%;
}

.padd-bottom {
    padding-bottom: 30px;
}


/*--------------------------------
            Offer Modal
-----------------------------------*/
.newsleetre_modal.modal-body .modal-bg {
    background-color: #e5e5e7;
    padding: 0px;
}

.newsleetre_modal.modal-body {
    padding: 0px;
}

.newsleetre_modal.modal-body .offer_modal_img {
    text-align: right;
}

.newsleetre_modal.modal-body .offer_modal_left {
    padding-left: 80px;
    padding-top: 30px;
}

.newsleetre_modal .modal-body .offer_modal_left img {
    width: 200px;
    height: 60px;
}

.newsleetre_modal .modal-body .offer_modal_left h3 {
    font-size: 18px;
    font-weight: 700;
    padding-top: 25px;
}

.newsleetre_modal .modal-body .offer_modal_left p {
    font-size: 14px;
    line-height: 24px;
    padding-top: 15px;
}

.newsleetre_modal .modal-body .offer_modal_left form input {
    border: none;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.newsleetre_modal.modal-body .offer_modal_left form label {
    font-size: 14px;
}

.newsleetre_modal .modal-body .offer_modal_left form button {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.newsleetre_modal .modal-body .offer_modal_left form .check_boxed_modal {
    padding-top: 10px;
    padding-left: 20px;
}

.newsleetre_modal .modal-body .offer_modal_left form {
    padding-top: 30px;
}

.newsleetre_modal .modal-body .close {
    padding-right: 10px;
    padding-top: 13px;
    position: absolute;
    right: 25px;
    top: 0;
    font-size: 30px;
    cursor: pointer;
    z-index: 999;
}


/* ----------------------------
     Cart 3 Page
-------------------------------*/
#cart_area_three .coupon_code {
    margin-top: 0;
}

#cart_area_three .cart_submit {
    padding: 20px 0 0 0;

}

#total_cart_form_three .rigth_cart {
    display: inline-block;
    float: right;
}

.total_cart_inner {
    padding: 20px 20px;
}

.total_cart_inner h5 {
    padding-bottom: 20px;
    font-weight: 700;
}

.estimate_for_country {
    font-weight: 800;
    padding-top: 10px;
}

.total_catr_three_bottom {
    margin-top: 30px;
    border-top: 1px solid #e7e7e7;
    padding-top: 12px;
}

.total_catr_three_bottom h5 {
    font-weight: 500;
}

.total_catr_three_bottom h5 span {
    float: right;
}

.bottom_code {
    margin-top: 30px !important;
}

/* ----------------------------
    Product Details 3 Page
-------------------------------*/
#product_single_three .list_three_pro li {
    padding-bottom: 10px;
}

#product_single_three .list_three_pro li span {
    padding-right: 10px;
}

#product_single_three .list_three_pro {
    padding-top: 23px;
}


#product_single_three .customs_selects {
    padding-top: 30px;
}

#product_single_three .variable-single-item {
    padding-top: 10px;
}

#product_single_three .reviews_rating {
    padding-top: 15px;
}

#product_single_three .modal_product_content_one h4 {
    padding-top: 20px;
}

#product_single_three .modal_product_content_one h4 del {
    padding-left: 20px;
    color: var(--main-theme-color);
}

#product_single_three .modal_product_content_one p {
    padding-top: 20px;
}

#product_single_three .links_Product_areas ul li {
    display: inline-flex;
    padding-right: 20px;
}

#product_single_three .links_Product_areas ul li a i {
    padding-right: 5px;
}

#product_single_three .links_Product_areas .theme-btn-one {
    margin-top: 20px;
}

.align_text_color {
    font-weight: 600;
    text-transform: capitalize;
    color: #24262b;
    position: relative;
    top: -9px;
    padding-right: 15px;
}

.product_details_cat {
    padding-top: 20px;
}

.product_details_cat ul li {
    display: inline-flex;
    font-weight: 600;
    text-transform: capitalize;
    color: #24262b;
    position: relative;
    top: -9px;
    padding-right: 8px;
}

.product_details_cat ul li a {
    font-weight: 400;
}

.product_details_bor {
    border-bottom: 1px solid #efefef;
}

.area_custom_details {
    display: flex;
    align-items: center;
}

.product_detail_socials {
    padding-top: 15px;
}

.product_detail_socials ul li {
    padding-left: 10px;
}

.product_detail_socials ul li a {
    display: inline-block;
    height: 36px;
    width: 36px;
    background: #ddd;
    text-align: center;
}

.product_detail_socials ul li a i {
    font-size: 18px;
    line-height: 36px;
    color: var(--heading-color);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.product_detail_socials ul li:hover a {
    background-color: var(--main-theme-color);
}

.product_detail_socials ul li:hover a i {
    color: #fff;
}

.product_detail_socials h6 {
    font-size: 16px;
    font-weight: 700;
}


.image-previews {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .image-preview-container {
    position: relative;
    margin-bottom: 10px; /* Espacio entre imágenes */
  }

  .delete-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border: none;

    padding: 10px;
    border-radius: 50%; /* Para hacer un botón redondo */
    cursor: pointer;
  }

  .delete-button:hover {
    background-color: darkred;
  }





/* --Sidebar-- */

.shop-sidebar-wrap h3 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.2px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    position: relative;
    display: block;
    border-bottom: 1px solid #ebebeb;
}

.shop-sidebar-wrap h3:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 60px;
    height: 2px;
    background: #ff7004;
}

.left-sidebar button {
    position: absolute;
    width: 50px;
    left: auto;
    right: 0;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    border-radius: 0;
    border-color: #ebebeb;
    background-color: #212121;
    color: #fff;
    padding: 0;
    z-index: 99999;
}

.search-widget form input {
    min-height: 50px;
}

.left-sidebar .category-post li {
    margin-bottom: 10px;
    display: block;
}

.left-sidebar .category-post li a {
    line-height: 24px;
    position: relative;
    display: block;
}

.left-sidebar .category-post li a span {
    float: right;
}

.left-sidebar .recent-post-widget .recent-single-post {
    margin: 0 0 30px;
}

.left-sidebar .recent-post-widget .recent-single-post .thumb-side {
    overflow: hidden;
}

.left-sidebar .recent-post-widget .recent-single-post .thumb-side img {
    max-width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: center;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side {
    margin: 0 0 0 18px;
    align-self: center;
    align-items: center;
    line-height: 24px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side h5 {
    font-size: 16px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side span {
    font-size: 14px;
    padding-top: 8px;
    display: block;
}

.shop-sidebar-wrap .sidebar-widget-tag li {
    line-height: 1;
    float: left;
    list-style: none;
}

.shop-sidebar-wrap .sidebar-widget-tag li:first-child a {
    margin-left: 0;
}

.shop-sidebar-wrap .sidebar-widget-tag li a {
    display: block;
    float: left;
    padding: 0 20px;
    line-height: 33px;
    font-weight: 400;
    border: 1px solid #ebebeb;
    border-radius: 30px;
    background: #fff;
    margin: 4px;
    text-transform: capitalize;
    color: #474747;
}

.shop-sidebar-wrap .sidebar-widget-tag li a:hover {
    background-color: var(--main-theme-color);
    border-color: var(--main-theme-color);
    color: #fff;
}

.flicker-widget li {
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    overflow: hidden;
}

.flicker-widget img {
    height: 70px;
    width: 70px;
    object-fit: cover;
    object-position: center;
}

.single_center_img {
    overflow: hidden;
}

.single_center_img img {
    width: 100%;
}

.related_blogs .blog_one_item {
    margin-bottom: 30px;
}

.follwos_icons ul li {
    display: inline-flex;
    padding-left: 20px;
}

.follwos_icons ul li:first-child {
    padding-left: 0px;
}

.follwos_icons p {
    padding-top: 20px;
}





/*--------------------------------
        Account Info Edit Page
-----------------------------------*/
.account_thumd {
    text-align: center;
    position: relative;
}

.account_thumd img {
    border-radius: 50%;
}

.account_thumd h4 {
    padding-top: 25px;
    padding-bottom: 5px;
}

.account_thumb_img {
    position: relative;
}

.fixed_icon {
    position: absolute;
    background: #ffffff;
    bottom: 6%;
    right: 10%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    font-size: 18px;
    transform: translate(-4px, -7px);
    box-shadow: 4px 6px 13px 0px #00000030;
}

.account_setting {
    padding-left: 60px;
}

.account_setting_heading {
    padding-bottom: 20px;
}

.account_setting_heading h2 {
    padding-bottom: 5px;
}

#account_info_form .form-control {
    margin-bottom: 20px;
    font-size: 14px;
}

#account_info_form .form-group {
    margin-bottom: 40px;
}

#account_info_form .input-radio {
    padding-bottom: 20px;
}

/*--------------------------------
    Order Completed Page
-----------------------------------*/
.order_complete i {
    color: #FF324D;
    font-size: 80px;
    margin-bottom: 20px;
}

.order_complete_heading {
    padding-bottom: 30px;
}

.order_complete p {
    padding-bottom: 30px;
}

/*--------------------------------
   Vendor Dashboard Page
-----------------------------------*/
.vendor_top_box {
    text-align: center;
    border: 1px solid #f79837;
    padding: 20px 0;
    box-shadow: 3px 5px 7px 1px #00000014;
    transition: .4s all ease-in-out;
}

.vendor_top_box:hover {
    box-shadow: none;
}

.vendor_top_box h2 {
    font-weight: 600;
    color: var(--main-theme-color);
}

.vendor_top_box h4 {
    margin-bottom: 0;
    font-weight: 400;
    padding-top: 5px;
}

.mychart_area {
    padding-top: 30px;
}


.pending_table {
    background: #fff;
    box-shadow: 3px 3px 6px 3px #00000008;
    overflow: hidden;
}

.pending_table thead tr th {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.pending_table tbody tr {
    /* border-bottom: 1px solid #eee; */
    margin-bottom: 10px;
}

.pending_table tbody tr td {
    vertical-align: middle;
    text-transform: capitalize;
    text-align: center;
}

.btn_right_table {
    text-align: right;
    margin-top: 30px;
}

/* --Vendor Profile-- */
.profils_details_vendor {
    display: flex;
}

.profils_details_vendor .profile_left {
    width: 150px;
    margin-right: 15px;
}

.profils_details_vendor .profile_left {
    display: flex;
    align-items: center;
}

.vendors_profiles ul li h4 {
    font-size: 16px;
    color: #787878;
    font-weight: 400;
}

.vendors_profiles .btn_left_table {
    padding-top: 10px;
}

/* --Setting Area-- */
.setings_boxed {
    box-shadow: 0px 0px 3px 2px #00000014;
    padding: 20px 20px;
    margin-bottom: 30px;
}

.setings_boxed h3 {
    font-size: 18px;
    padding-bottom: 10px;
}

.setings_boxed form input {
    margin-right: 10px;
}

/*--------------------------------
        Add Product Page
-----------------------------------*/
.add_product_form .fotm-group {
    margin-bottom: 20px;
}

.add_product_form .fotm-group select {
    display: block;
    width: 100%;
}

.add_product_wrapper h3 {
    padding-bottom: 30px;
}

.add_product_form {
    box-shadow: 0px 2px 8px 4px #0000000f;
    background: #fff;
    padding: 30px 25px;
}

.image-input {
    text-align: center;
}

.image-input input {
    display: none;
}



.image-input label {
    display: block;
    color: #FFF;
    background: #000;
    padding: 0.3rem 0.6rem;
    font-size: 17px;
    cursor: pointer;
    width: 26%;
    margin: 21px auto 33px auto;
}

.image-input label i {
    font-size: 125%;
    margin-right: 0.3rem;
}

.image-input label:hover i {
    animation: shake 0.35s;
}

.image-input img {
    max-width: 200px;
    max-height: 200px;
    border: 2px solid #ffc107;
    margin: 0 auto;
}

.back_to_area a {
    font-size: 14px;
    color: var(--main-theme-color);
}

.back_to_area {
    padding-bottom: 40px;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}