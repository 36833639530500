/*================================================
This file contains the following styles:


Home Configurations
=================================================
 --Banner Area
 --Hot Product Area
 --Filers CSS
 --Offer Timer One Area
 --To Days Area
 --Special Banner Area
 --Instagram Area Start
 --Footer Area Start
 --CopyRight Area
 --Footer Area End
 --About Page
 --Faqs Page
 --Privacy Policy Page
 --Coming Soon
 --Our Story Area
 --Banner Area
 --Banner Bottom Area
 --Offer Timer One
 --Furniture Popular Product
 --Preloader


=================================================*/

/* --Fashion--- */

/* -------------------------
   Banner Area
---------------------------*/
#banner_one {
  background-image: url("../images/banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  min-height: 300px; /* Altura mínima del contenedor */

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media (min-width: 600px) {
  #banner_one {
    min-height: 400px; /* Altura mínima para pantallas más anchas */
  }
}

@media (min-width: 768px) {
  #banner_one {
    min-height: 500px; /* Altura mínima para pantallas aún más anchas */
  }
}

@media (min-width: 992px) {
  #banner_one {
    min-height: 600px; /* Altura mínima para pantallas grandes */
  }
}

@media (min-width: 1200px) {
  #banner_one {
    min-height: 700px; /* Altura mínima para pantallas muy grandes */
  }
}

.banner_text_one h1 {
  font-size: 150px;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 150px;
}

.banner_text_one h3 {
  text-transform: uppercase;
  font-weight: 500;
  color: #f3f3f3;
}

.banner_text_one a {
  margin-top: 30px;
}

.banner_text_one h1 span {
  display: block;
  color: #fff;
  font-weight: 700;
}

.hero_img img {
  position: absolute;
  top: 0px;
  right: 0;
}

/* -------------------------
    Hot Product Area
---------------------------*/

/*  Filers CSS */
.tabs_center_button .nav-tabs {
  border-bottom: none;
  width: 100%;
  justify-content: center;
}

.tabs_center_button .nav-tabs li a {
  margin-left: 50px;
  transition: inherit;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  background: transparent;
  padding-bottom: 5px;
}

.tabs_center_button .nav-tabs li:first-child a {
  margin-left: 0px;
}

.tabs_center_button .nav-tabs li a:hover {
  color: var(--main-theme-color);
}

.tabs_center_button .nav-tabs li .active {
  color: var(--main-theme-color) !important;
  border-bottom: 2px solid var(--main-theme-color);
}

/* -------------------------
     Offer Timer One Area
---------------------------*/

#offer_timer_one {
  background-image: url("../images/t-shirt.png");
  background-size: cover;
  background-position: center;
  padding: 145px 0;
}

.offer_timer_text {
  padding-left: 60px;
}

.offer_timer_text h2 {
  font-size: 40px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.offer_timer_text p {
  padding-top: 15px;
  padding-bottom: 30px;
}

#countdown ul li {
  border: 1px solid #c5c5c5;
  width: 100px;
  height: 70px;
  text-align: center;
  padding-top: 10px;
  margin-top: 30px;
  font-size: 14px;
}

#countdown ul li span {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #545454;
}

#countdown ul li:first-child {
  margin-top: 0;
}

.offer_time_flex {
  display: flex;
}

/* -------------------------
     To Days Area
---------------------------*/

.product_wrappers_one {
  overflow: hidden;
}

.product_wrappers_one .thumb {
  position: relative;
  overflow: hidden;
}

.product_wrappers_one .thumb .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.product_wrappers_one .thumb .image img {
  z-index: 1;
  max-width: 100%;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.product_wrappers_one .thumb .image img.hover-image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
}

.product_wrappers_one .thumb .badges {
  position: absolute;
  z-index: 8;
  top: 18px;
  left: 18px;
  display: flex;
  flex-direction: column;
}

.product_wrappers_one .thumb .badges span {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  padding: 0 7px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 3px;
  color: #fff;
}

.product_wrappers_one .thumb .badges span + span {
  margin-top: 10px;
}

.product_wrappers_one .thumb .badges span.new {
  background-color: #007a58;
}

.product_wrappers_one .thumb .badges span.hot {
  background-color: #2664fb;
}

.product_wrappers_one .thumb .badges span.sale {
  background-color: #ff7004;
}

.product_wrappers_one .thumb .actions {
  position: absolute;
  z-index: 9;
  top: 18px;
  right: 18px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease 0s;
}

.product_wrappers_one .thumb .actions .action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: #212121;
  border-radius: 50%;
  background-color: #fff;
}

.product_wrappers_one .thumb .actions .action i {
  font-size: 14px;
  line-height: 1;
}

.product_wrappers_one .thumb .actions .action + .action {
  margin-top: 10px;
}

.product_wrappers_one .thumb .actions .action:not(.wishlist) {
  visibility: hidden;
  transform: translateY(20px);
  opacity: 0;
}

.product_wrappers_one .thumb .actions .action.active {
  color: #ff7004;
}

.product_wrappers_one .thumb .actions .action:hover:not(.active) {
  color: #fff;
  background-color: #ff7004;
}

.product_wrappers_one .thumb .add-to-cart {
  display: block;
  width: 80%;
  height: 45px;
  line-height: 45px;
  background-color: #fff;
  text-align: center;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 11;
  margin: auto;
  font-weight: 600;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transform: translateY(20px);
  color: #3f3f3f;
  opacity: 0;
  font-family: Raleway, sans-serif;
}

.product_wrappers_one .thumb .add-to-cart:hover {
  color: #ff7004;
}

.product_wrappers_one .content {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 23px 15px;
  transition: all 0.3s ease 0s;
  text-align: center;
  background-color: #fff;
}

.product_wrappers_one .content .title {
  font-size: 14px;
  margin: 0px 0 10px;
}

.product_wrappers_one .content .title a {
  text-decoration: none;
  color: #1d1d1d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 16px;
}

.product_wrappers_one .content .price {
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
}

.product_wrappers_one .content .price:not(:last-child) {
  margin-bottom: 20px;
}

.product_wrappers_one .content .price span.new {
  color: #3f3f3f;
  font-weight: 500;
}

.product_wrappers_one .content .price span.old {
  font-size: 14px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999;
}

.product_wrappers_one .content .btn {
  align-self: center;
}

.product_wrappers_one:hover .thumb .image img {
  transform: scale(1.1) rotate(3deg);
}

.product_wrappers_one:hover .thumb .image img:not(:last-child) {
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.product_wrappers_one:hover .thumb .image img.hover-image {
  opacity: 1;
  transition: 0.5s all ease-in-out;
}

.product_wrappers_one:hover .thumb .actions .action {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.product_wrappers_one:hover .thumb .add-to-cart {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.product_wrappers_one:hover .content .title a {
  color: #474747;
}

.product_wrappers_one:hover .content .title a:hover {
  color: #ff7004;
}

/* -------------------------
 Special Banner Area
---------------------------*/

#special_offer_one {
  background-image: url("../images/offer-banner.png");
  background-size: cover;
  background-position: center;
  padding: 115px 0;
}

.offer_banner_one h5 {
  color: var(--main-theme-color);
}

.offer_banner_one h2 {
  color: #fff;
  padding: 10px 0;
}

.offer_banner_one p {
  color: #fff;
  padding-bottom: 30px;
}

/* -------------------------
  Instagram Area Start
---------------------------*/

.instgram_post {
  position: relative;
}

.instgram_post > a {
  display: block;
  position: relative;
  z-index: 1;
}

.instgram_post > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f3f0ea;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.instgram_post > a i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(1.2) translate(-50%, -50%);
  z-index: 2;
  font-size: 40px;
  color: #262626;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}

.instgram_post > a:hover::before {
  opacity: 0.7;
}

.instgram_post > a:hover i {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.instgram_post img {
  width: 100%;
}

.instagram_post_slider {
  margin-bottom: -7px;
}

/* -------------------------
  Footer Area Start
---------------------------*/

#footer_one {
  padding: 70px 0;
  background-color: #f6f6f6;
}

#footer_one .footer_left_side p {
  padding-top: 15px;
}

#footer_one .footer_left_side h2 {
  padding-top: 15px;
}

#footer_one .footer_one_widget ul {
  padding-top: 15px;
}

#footer_one .footer_one_widget h3 {
  text-transform: uppercase;
  font-size: 18px;
}

#footer_one .footer_one_widget ul li {
  padding-top: 10px;
}

#footer_one .footer_one_widget ul li:first-child {
  padding-top: 0px;
}

#footer_one .footer_left_side_icon {
  padding-top: 20px;
}

#footer_one .footer_left_side_icon ul li {
  display: inline-flex;
  padding-left: 10px;
}

#footer_one .footer_left_side_icon ul li:first-child {
  padding-left: 0px;
}

#footer_one .footer_left_side_icon ul li a i {
  font-size: 18px;
}

#footer_one .footer_left_side p {
  padding-right: 50px;
}

#mc_embed_signup {
  padding-top: 20px;
}

#mc_embed_signup button {
  margin-top: 20px;
  background-color: #000;
  color: #fff;
}

#mc_embed_signup button:hover {
  background-color: #262626;
  color: #fff;
}

/* --CopyRight Area-- */

#copyright_one {
  padding: 15px 0;
  border-top: 1px solid #ededed;
  background: #000;
}

.copyright_left h6 {
  font-size: 14px;
  color: #fff;
}

#copyright_one .copyright_right {
  text-align: right;
}

#copyright_one .copyright_left h6 span {
  font-weight: 600;
  color: var(--main-theme-color);
}

/* -------------------------
  Footer Area End
---------------------------*/

/* ---------------------------------
     About Page
-------------------------------------*/

.about_top_img img {
  width: 100%;
}

.about_top_left_content h2 {
  text-transform: uppercase;
}

.about_top_left_content h4 {
  line-height: 30px;
  padding-top: 15px;
  font-weight: 400;
}

.about_top_left_content p {
  padding-top: 10px;
}

#about_progressbar {
  background: #f6f6f6;
}

.about_progressbar_content p {
  padding-top: 20px;
}

.skill-progressbar {
  margin-bottom: 35px;
}

.skill-progressbar:last-child {
  margin-bottom: 0;
}

.service_promo_single_item {
  text-align: center;
  box-shadow: 0px 0px 6px 1px #0000001c;
  padding: 25px 10px;
  height: 100%;
}

.service_prom_content {
  padding-top: 10px;
}

.service_prom_content p {
  padding-top: 10px;
}

#team_area {
  background-color: #f6f6f6;
}

.team-single {
  background: #fff;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 25px;
}

.team-content {
  position: relative;
  font-size: 16px;
  padding-top: 20px;
}

.team-name {
  font-weight: 700;
  font-size: 20px;
}

.team-img img {
  margin: 0 auto;
}

.team-social {
  width: 100%;
  background: #fff;
  bottom: -22px;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  font-size: 16px;
  padding: 10px 0;
}

.team-social li {
  display: inline-block;
  margin-right: 10px;
}

.team-social li:last-child {
  margin-right: 0;
}

.team-social a {
  color: #000;
  transition: all 0.5s ease;
}

.team-social a:hover {
  color: #b19361;
}

.team-single:hover .team-social {
  bottom: -24px;
  opacity: 1;
  visibility: visible;
}

.pos-absolute {
  position: absolute;
}

/* ---------------------------------
   Faqs Page
-------------------------------------*/

.faqs_area_wrapper .card_items_area {
  margin-bottom: 20px;
}

.faqs_area_wrapper .collapse {
  background: #f7f7f7;
  padding: 20px 20px;
}

.faqs_area_wrapper .collapse .card-body {
  padding: 0px;
}

.faqs_area_wrapper .card-header {
  width: 100%;
  text-align: left;
  font-size: 18px;
  padding: 0;
}

.faqs_area_wrapper .card-header a h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

/* ---------------------------------
   Privacy Policy Page
-------------------------------------*/

.privacy_policy_text {
  padding-top: 30px;
}

.privacy_policy_text p {
  padding-top: 20px;
}

/* ---------------------------------
   Coming Soon
-------------------------------------*/

#coming_soon_area {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  /* background-image: url("../images/coming-soon.jpg"); */
  position: relative;
  display: flex;
  align-items: center;
}

#coming_soon_area::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000054;
}

.coming_soon_content {
  text-align: center;
  z-index: 999;
  position: relative;
}

.coming_soon_title {
  padding-top: 30px;
}

.coming_soon_title h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
}

.coming_soon_title p {
  color: #fff;
  padding-top: 10px;
}

.coming_soon_time {
  padding-top: 40px;
}

#countdown_soon ul {
  display: flex;
}

#countdown_soon ul li {
  border: 1px solid #fff;
  width: 100%;
  color: #fff;
  padding: 15px 0;
  font-size: 20px;
  margin-left: 20px;
}

#countdown_soon ul li:first-child {
  margin-left: 0;
}

#countdown_soon ul li span {
  display: block;
  font-size: 45px;
  line-height: 55px;
}

.coming_soon_newsletter {
  padding-top: 40px;
}

.coming_soon_newsletter h3 {
  color: #fff;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-style: italic;
}

.coming_soon_newsletter form input {
  min-height: 50px;
}

/* -------------------------
  Our Story Area
---------------------------*/
#furniture_story {
  background-image: url("../images/parallex.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 150px 0;
  position: relative;
}

#furniture_story::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  position: absolute;
  top: 0;
}

.our_story_content {
  text-align: center;
  z-index: 9;
  position: relative;
}

.our_story_content p {
  padding: 40px 0;
}

.our_story_content h2,
.our_story_content p {
  color: #fff;
}

/* -------------------------
     Banner Area
---------------------------*/
.bg_1 {
  background-image: url("../images/banner1.png");
}

.bg_2 {
  background-image: url("../images/banner2.png");
}

.bg_3 {
  background-image: url("../images/banner3.png");
}

.background_bg {
  background-position: center center;
  background-size: cover;
}

.furniture_slider {
  padding: 200px 0;
}

.furniture_slider_content h5 {
  opacity: 0;
  -webkit-transition: all 1200ms ease;
  -o-transition: all 1200ms ease;
  transition: all 1200ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content h2 {
  padding: 35px 0;
  font-size: 60px;
  font-weight: 700;
  opacity: 0;
  -webkit-transition: all 1400ms ease;
  -o-transition: all 1400ms ease;
  transition: all 1400ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content a {
  opacity: 0;
  -webkit-transition: all 1600ms ease;
  -o-transition: all 1600ms ease;
  transition: all 1600ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content p {
  padding-bottom: 30px;
  opacity: 0;
  -webkit-transition: all 1500ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

#furniture_banner .slick-active .furniture_slider_content h5 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

#furniture_banner .slick-active .furniture_slider_content h2 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

#furniture_banner .slick-active .furniture_slider_content p {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

#furniture_banner .slick-active .furniture_slider_content a {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1700ms;
  -o-transition-delay: 1700ms;
  transition-delay: 1700ms;
}

#furniture_banner .owl-theme .owl-dots {
  position: absolute;
  top: 0;
  right: 50px;
  bottom: 0;
  margin: 0;
  width: 50px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#furniture_banner .owl-theme .owl-dots .owl-dot {
  position: relative;
  display: block;
  width: 30px;
  height: 5px;
  background: var(--main-theme-color);
  border: 0px solid;
  margin: 5px 0px;
  padding: 0px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

#furniture_banner .owl-theme .owl-dots .owl-dot span {
  display: none;
}

#furniture_banner .owl-theme .owl-dots .owl-dot.active {
  width: 40px;
}

#furniture_banner .owl-theme .owl-dots .owl-dot:before {
  position: absolute;
  top: 0px;
  right: -10px;
  bottom: 0px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  content: "";
  background: var(--main-theme-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/* -------------------------
     Banner Bottom Area
---------------------------*/
.furniture_bottom_wrapper {
  position: relative;
}

.furniture_bottom_wrapper .hover_effect_furniture {
  position: relative;
  display: block;
  outline: none !important;
}

.furniture_bottom_wrapper .hover_effect_furniture::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}

.furniture_bottom_wrapper:hover > .hover_effect_furniture:before {
  visibility: visible;
  opacity: 1;
}

.furniture_bottom_wrapper .hover_effect_furniture::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}

.furniture_bottom_wrapper a img {
  width: 100%;
}

.furniture_bottom_content {
  display: inline-block;
  position: absolute;
  padding-top: 0.4rem;
  left: 2rem;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.furniture_bottom_content a {
  color: #fff;
  border-bottom: 2px solid var(--main-theme-color);
}

.furniture_bottom_content a:hover {
  color: var(--main-theme-color);
}

.furniture-content-lg h5 {
  color: #fff;
}

.furniture-content-lg h2 {
  color: #fff;
  padding: 10px 0;
  line-height: 50px;
}

.furniture-content-md h5 {
  color: #fff;
  line-height: 0;
  padding-bottom: 2px;
  font-size: 16px;
}

.furniture-content-md h2 {
  color: #fff;
  padding: 13px 0;
  line-height: 28px;
  font-size: 22px;
}

/* -------------------------
     Offer Timer One
---------------------------*/
#offer_timer_two {
  background: #5a6b85;
  padding-top: 100px;
}

.offer_time_two_Content {
  text-align: center;
}

.offer_time_two_Content img {
  width: 60%;
}

#countdown_two {
  padding-top: 36px;
}

#countdown_two ul {
  display: flex;
  justify-content: center;
}

#countdown_two li {
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin-left: 35px;
  padding: 10px 20px;
  position: relative;
  border: 1px solid var(--main-theme-color);
}

#countdown_two li::before {
  content: "";
  position: absolute;
  width: 30px;
  right: -10px;
  height: 35px;
  background: #5a6b85;
  top: -10px;
}

#countdown_two li::after {
  content: "";
  position: absolute;
  width: 30px;
  left: -10px;
  height: 35px;
  background: #5a6b85;
  bottom: -10px;
}

#countdown_two li:first-child {
  margin-left: 0px;
}

#countdown_two li span {
  display: block;
  font-size: 32px;
  font-style: italic;
  line-height: 49px;
  z-index: 99;
  position: relative;
}

/* -------------------------
  Furniture Popular Product
---------------------------*/
#furniture_popular_product .product_box {
  margin: 0px 15px 15px 15px;
}

/*--------------------------------
            Preloader
-----------------------------------*/
#preloader {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999999;
}

#status {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

#paralex_area {
  background-image: url("../images/paralex.png");
  padding: 145px 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
}

.paralex_text {
  text-align: center;
}

.paralex_text h3 {
  color: #fff;
}

.paralex_text h2 {
  font-size: 60px;
  color: #fff;
  padding: 30px 0;
}

.paralex_text p {
  color: #fff;
  padding: 0 155px;
  font-size: 18px;
  padding-bottom: 30px;
}

#explore_product_jewellary .tabs_center_button {
  padding-bottom: 40px;
}

#jewellary_customer_service {
  background: #f4f4f4;
  padding: 100px 0;
}

.jewel_serv_card {
  text-align: center;
}

.jewel_serv_card i {
  font-size: 40px;
  padding-bottom: 20px;
}

.jewel_serv_card h4 {
  padding-bottom: 5px;
}

.jewellary_blog_card {
  text-align: center;
}

.jewellary_blog_img {
  overflow: hidden;
}

.jewellary_blog_img img {
  transition: 0.4s all ease-in-out;
  width: 100%;
}

.jewellary_blog_card:hover .jewellary_blog_img img {
  transform: scale(1.2);
}

.jewellary_blog_content {
  padding-top: 25px;
}

.jewellary_blog_content h3 {
  padding-bottom: 15px;
}

.jewellary_blog_card:hover .jewellary_blog_content h3 a {
  color: var(--main-theme-color);
}

.jewellary_blog_content h6 {
  font-size: 16px;
}

.jewellary_blog_content h6 a {
  color: var(--main-theme-color);
}

.jewellary_blog_content p {
  padding-top: 15px;
  padding-bottom: 15px;
}

.jewellary_blog_content .read_more {
  color: var(--main-theme-color);
}

.grocery_banner_img img {
  width: 100%;
}
