/*================================================
This file contains the following styles:


Products Styling
=================================================
 --Product variation Area
 --product_slider_one Modal
 --Shop Page
 --Shop List Page
 --Shop Sidebar Page
 --Product Single Page
 --Product Single Two Page
 --Product Hover Page
 --Product Hover Three

=================================================*/


/* -------------------------
   Product variation Area
---------------------------*/

.product_variation_one_boxed {
    margin-bottom: 30px;
}

.row .col-lg-4 .product_variation_one_boxed:last-child {
    margin-bottom: 0;
}

.product_variation_one_boxed {
    position: relative;
    overflow: hidden;
}

.product_variation_one_boxed img {
    width: 100%;
}

.product_var_one_text_center {
    position: absolute;
    left: 40px;
    bottom: 0;
    padding: 20px;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50%;
}

.product_var_one_text {
    position: absolute;
    left: 40px;
    top: 50%;
    padding: 20px;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50%;
}

.product_var_one_text h2,
.product_var_one_text_center h2 {
    padding: 5px 0;
    text-transform: uppercase;
    font-weight: 700
}

.product_var_one_text h4,
.product_var_one_text_center h4 {
    text-transform: uppercase;
}

.product_var_one_text a,
.product_var_one_text_center a {
    margin-top: 20px;
}

.color_one {
    color: var(--main-theme-color);
}



/* -------------------------
 product_slider_one Modal
---------------------------*/

#product_slider_one .owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
    margin: 5px 7px;
    background: var(--heading-color);
}

#product_slider_one .owl-theme .owl-dots .owl-dot.active span,
#product_slider_one.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-theme-color);
    width: 20px;
    height: 6px;
}

.product_one_modal_top .close_modal_icon {
    position: absolute;
    right: 0px;
    z-index: 10;
    top: 0px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    border-radius: 50%;
    transition: .3s;
    display: inline-block;
    background: #fff;
    border: 0;
}

.product_one_modal_top .close_modal_icon:hover {
    background: var(--main-theme-color);
    color: #fff;
    transform: rotate(90deg);
}

.modal_product_content_one .reviews_rating {
    padding-top: 10px;
}

.modal_product_content_one .reviews_rating i {
    color: var(--main-theme-color);
}

.modal_product_content_one .reviews_rating span {
    padding-top: 4px;
}

.modal_product_content_one h4 {
    padding-top: 10px;
}

.modal_product_content_one p {
    padding-top: 10px;
}

#product_count_form_one {
    padding-top: 20px;
}

#product_count_form_one input {
    width: 20%;
    min-height: 40px;
    border-radius: 0;
    margin: 0 8px;
    text-align: center;
}

#product_count_form_two {
    padding-top: 20px;
}

#product_count_form_two input {
    width: 20%;
    min-height: 40px;
    border-radius: 0;
    margin: 0 8px;
    text-align: center;
}

.product_count_one a {
    margin-right: 29px;
    margin-top: 30px;
}

.variable-single-item {
    margin-top: 15px;
}

.variable-single-item>span {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: capitalize;
    color: #24262b;
}

.product-variable-color label {
    line-height: 0;
    margin-right: 5px;
    position: relative;
}

.product-variable-color label input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}

.product-variable-color label span {
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #e9ecef;
    border-radius: 2px;
    cursor: pointer;
}

.product-variable-color label .product-color-red {
    background: red;
}

.product-variable-color label .product-color-tomato {
    background: tomato;
}

.product-variable-color label .product-color-green {
    background: green;
}

.product-variable-color label .product-color-light-green {
    background: #90ee90;
}

.product-variable-color label .product-color-blue {
    background: #00f;
}

.product-variable-color label .product-color-light-blue {
    background: #add8e6;
}

.product-variable-color label span::after {
    position: absolute;
    color: #fff;
    content: "";
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
}

.product-variable-color label input:checked~span::after {
    font-family: FontAwesome;
    content: "\f00c";
}

.modal_share_icons_one {
    padding-top: 15px;
}

.posted_icons_one {
    padding-top: 10px;
}

.posted_icons_one button {
    padding-right: 6px !important;
    transition: all .4s;
}

.posted_icons_one button:hover {
    transform: scale(1.2)
}

.modal_share_icons_one h4 {
    font-size: 18px;
    font-weight: 500;
}

.posted_icons_one a svg {
    width: 16px;
    height: 16px;
}

.posted_icons_one a {
    border: 1px solid var(--main-theme-color);
    margin-right: 17px;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}

.plus-minus-input {
    display: flex;
    align-items: center;
}

.plus-minus-input input::-webkit-outer-spin-button,
.plus-minus-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.plus-minus-input input[type=number] {
    -moz-appearance: textfield;
}

.plus-minus-input button {
    background: #000;
    color: #fff;
    font-size: 30px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 6px;
    position: relative;
}

.plus-minus-input button i {
    font-size: 13px;
}

.plus-minus-input svg {
    width: 16px;
    height: 18px;
    position: absolute;
    left: 21%;
    top: 4px;
}


/* ---------------------------------
       Shop Page
-------------------------------------*/

.customs_sel_box {
    width: 200px;
    padding: 12px;
    font-size: 16px;
    line-height: 1;
    height: 45px;
    background: url(../images/arrow.png) no-repeat right;
    -webkit-appearance: none;
    background-position-x: 170px;
    border: 1px solid;
    background-size: 6%;
}

#to_days_area .product_wrappers_one {
    margin: 0 15px 15px 15px;
}

.product_wrappers_one {
    margin-top: 30px;
    box-shadow: 0px 3px 15px 0px #0000000f;
}

.product_shot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.product_shot_title p {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    text-transform: uppercase;
    padding-right: 20px;
}

.product_shot_view ul {
    display: flex;
}

.product_shot_view ul li {
    padding-left: 10px;
}

.product_shot_view ul li:first-child {
    padding-left: 20px;
}

.product_shot_view ul li i {
    font-size: 20px;
}

.pagination {
    padding: 0;
    display: block;
    margin: 40px 0 0;
    text-align: center;
}

.pagination .page-item {
    display: inline-block;
    margin: 0 3px;
}

.pagination .page-item .page-link {
    color: var(--heading-color);
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f5f5f5;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.pagination .page-item.active .page-link {
    background-color: var(--main-theme-color);
    color: #ffffff;
    -webkit-box-shadow: 0px 7px 20px 0px #dcdcdc;
    box-shadow: 0px 7px 20px 0px #dcdcdc;
}

.product_shot_view .active {
    color: var(--main-theme-color);
}


/* ---------------------------------
       Shop List Page
-------------------------------------*/

.product_wrappers_list {
    background: #ffffff;
    padding: 40px 40px;
    box-shadow: 2px 2px 15px 2px #0000000a;
    margin-bottom: 30px;
}

#shop_main_area .row:nth-child(2) .col-lg-12:first-child .product_wrappers_list {
    margin-top: 30px;
}

.product_wrappers_list .content .product_para_top p {
    padding-top: 5px;
}

.product_wrappers_list .content .product_para_top .price {
    font-size: 20px;
    font-weight: 600;
    color: var(--main-theme-color);
    display: block;
    padding-top: 10px;
}

.product_wrappers_list .actions {
    padding-top: 10px;
}

.product_wrappers_list .actions a {
    padding-right: 10px;
    font-size: 18px;
}

.product_wrappers_list .content a {
    margin-top: 20px;
}


/* ---------------------------------
       Shop Sidebar Page
-------------------------------------*/

.custom_boxed {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom_boxed:last-child {
    margin-bottom: 0;
}

.custom_boxed input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.custom_boxed:hover input~.checkmark {
    background-color: var(--main-theme-color);
}

.custom_boxed input:checked~.checkmark {
    background-color: var(--main-theme-color);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom_boxed input:checked~.checkmark:after {
    display: block;
}

.custom_boxed .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.shop_sidebar_wrapper {
    padding-top: 30px;
}

.shop_Search {
    position: relative;
}

.shop_Search form input {
    padding: 10px 46px 10px 17px;
    border-radius: 0;
}

.shop_Search form button {
    background: none;
    position: absolute;
    top: 8px;
    right: 15px;
}

.shop_Search form button img {
    width: 20px;
}

.shop_sidebar_boxed {
    padding-top: 30px;
}

.shop_sidebar_boxed h4 {
    margin-bottom: 20px;
    font-size: 18px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
}

.price_filter .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    background: #312620 none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    height: 6px;
    margin-bottom: 20px;
    margin-left: auto;
    border-radius: 3px;
}

.price_filter .ui-slider-range {
    background: #312620 none repeat scroll 0 0;
    border-radius: 2px;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all {
    height: 20px;
    width: 20px;
    top: 0;
    margin-top: -7px;
    background: #fff;
    border-radius: 50%;
    margin-left: 0;
    border: 3px solid #312620;
    transition: .1s linear;
    display: block;
}

.price_filter .range_count {
    display: flex;
    justify-content: space-between;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all:focus {
    outline: none;
    box-shadow: none;
}

.price_slider_amount {
    overflow: hidden;
}

.price_slider_amount>span {
    font-size: 14px;
    font-weight: 500;
    color: #544842;
    text-transform: capitalize;
    margin-right: 5px;
}

.price_slider_amount>input[type="text"] {
    height: auto;
    margin-left: 0;
    text-align: left;
    width: 100px;
    border: none;
    font-size: 14px;
    color: #544842;
    font-weight: 500;
}

.price_slider_amount>input[type="submit"] {
    float: left;
    text-transform: uppercase;
    transition: all 400ms ease-out 0s;
    cursor: pointer;
    width: 98px;
    padding: 11px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.price_filter {
    margin-top: 30px;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all:hover {
    border-color: #ff6000;
}

#sizes_input .custom_boxed {
    padding-left: 25px;
    margin-bottom: 0px;
    margin-right: 20px;
    display: inline-block;
}

.skillbar {
    margin-bottom: 40px !important;
}

/* ---------------------------------
      Product Single Page
-------------------------------------*/
.slider-nav .nav_img {
    margin-left: 15px;
}

.product_img_two_slider {
    margin-bottom: 30px;
}

.product_img_two_slider img {
    width: 100%;
}

.slider-nav .nav_img:first-child {
    margin-left: 0;
}

#product_single_one .customs_selects {
    padding-top: 30px;
}

#product_single_one .variable-single-item {
    padding-top: 10px;
}

#product_single_one .reviews_rating {
    padding-top: 15px;
}

#product_single_one .modal_product_content_one h4 {
    padding-top: 20px;
}

#product_single_one .modal_product_content_one h4 del {
    padding-left: 20px;
    color: var(--main-theme-color);
}

#product_single_one .modal_product_content_one p {
    padding-top: 20px;
}

#product_single_one .links_Product_areas {
    padding-top: 30px;
}

#product_single_one .links_Product_areas ul li {
    display: inline-flex;
    padding-right: 20px;
}

#product_single_one .links_Product_areas ul li a i {
    padding-right: 5px;
}

#product_single_one .links_Product_areas .theme-btn-one {
    margin-top: 20px;
}

.product_single_one_img img {
    width: 100%;
}

.product_details_tabs {
    padding-top: 60px;
}

.product_details_tabs .nav-tabs {
    border-bottom: 1px solid #dadada;
    padding-bottom: 10px;
}

.product_details_tabs ul li {
    padding-right: 30px;
}

.product_details_tabs .nav-tabs li a {
    font-size: 18px;
    font-weight: 500;
    color: #afafaf;
}

.product_details_tabs ul li a.active {
    color: var(--main-theme-color);
}

#description .product_description {
    padding-top: 20px;
}

#description .product_description ul {
    padding-left: 55px;
    padding-top: 20px;
}

#description .product_description ul li {
    list-style: disc;
    padding-top: 10px;
}

#description .product_description ul li:first-child {
    padding-top: 0px;
}

#description .product_description ul li:last-child {
    padding-bottom: 10px;
}

#additional .product_additional {
    padding-top: 20px;
}

#additional ul li {
    padding-top: 10px;
    font-weight: 600;
    color: #6b6b6b;
}

#additional ul li:first-child {
    padding-top: 0px;
}

#additional ul li span {
    color: var(--main-theme-color);
    display: block;
    padding-top: 8px;
}

#review .product_reviews {
    padding-top: 20px;
}

#review .product_reviews .media-public ul li {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
}

#review .product_reviews .media-img {
    padding-right: 20px;
}

#review .product_reviews .media .media-img img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

#review .product_reviews .media-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#review .product_reviews ul .media {
    box-shadow: 0px 0px 15px 6px #96969614;
    padding: 44px 50px;
    margin-top: 30px;
}

#review .product_reviews ul .media:first-child {
    margin-top: 0;
}

#review .product_reviews .media-header .media-name h4 {
    font-size: 20px;
    padding-bottom: 10px;
}

#review .product_reviews .media-header .post-share a {
    font-size: 16px;
    color: #848484;
    padding-left: 18px;
}

#review .product_reviews .media-header .post-share a:hover {
    color: var(--main-theme-color);
}

#review .product_reviews .media-pragraph p {
    padding-top: 5px;
}

#review .product_reviews .media-pragraph .product_review_strat span i {
    color: var(--main-theme-color);
}

#review .product_reviews .media-pragraph {
    padding-top: 5px;
}


/* ---------------------------------
      Product Single Two Page
-------------------------------------*/

#product_single_two .owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 44%;
}

#product_single_two .owl-prev {
    background: black;
    width: 25px;
    height: 35px;
    color: #fff;
}

#product_single_two .owl-next {
    background: black;
    width: 25px;
    height: 35px;
    color: #fff;
}

#product_single_two .owl-prev:hover,
#product_single_two .owl-next:hover {
    background: var(--main-theme-color);
}

#product_single_two .customs_selects {
    padding-top: 30px;
}

#product_single_two .variable-single-item {
    padding-top: 10px;
}

#product_single_two .reviews_rating {
    padding-top: 15px;
}

#product_single_two .modal_product_content_one h4 {
    padding-top: 20px;
}

#product_single_two .modal_product_content_one h4 del {
    padding-left: 20px;
    color: var(--main-theme-color);
}

#product_single_two .modal_product_content_one p {
    padding-top: 20px;
}

#product_single_two .links_Product_areas {
    padding-top: 30px;
}

#product_single_two .links_Product_areas ul li {
    display: inline-flex;
    padding-right: 20px;
}

#product_single_two .links_Product_areas ul li a i {
    padding-right: 5px;
}

#product_single_two .links_Product_areas .theme-btn-one {
    margin-top: 20px;
}


/* -------------------------
  Product Hover Page
---------------------------*/
#elce_weekly_deal .product_item_two {
    margin: 30px 15px 0 0;
}

.product_item_two {
    margin-top: 30px;
}

.product_hover_heading {
    text-align: center;
}

/* --Product Hover TWo-- */
.product_item_two .product_item_inner {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
}

.product_item_two.product_item_inner img {
    width: 100%;
}

.product_item_two .product_button {
    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
    opacity: 0;
    bottom: -50px;
    left: 50%;
    visibility: hidden;
    position: absolute;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    border: 1px solid #eeeeee;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: table;
    text-align: center;
}

.product_item_two .product_button a {
    border-right: 1px solid #eeeeee;
    font-size: 18px;
    padding: 15px 20px;
    display: table-cell;
}

.product_button a:last-child {
    border: none;
}

.product_item_two .product_detail {
    overflow: hidden;
    text-align: center;
    padding-top: 10px;
}

.product_item_two:hover .product_button {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}

.product_item_two .product_detail .product_title a {
    font-size: 16px;
}

.product_item_two .product_detail .item_price {
    padding-top: 5px;
    font-weight: 600;
}

/* --Product Hover Three-- */
#hot_Product_area .product_box {
    margin-top: 30px;
}

.product_box {
    margin-bottom: 0px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;

}

.product_img {
    position: relative;
    overflow: hidden;
}

.product_img::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: 1;
}

.product_img img {
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.product_action_box {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    right: 0;
    text-align: center;
    padding-top: 0;
    transition: all .5s ease-in-out;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.pr_action_btn {
    display: table;
    margin: 0 auto;
}

.product_action_box li:nth-child(1) {
    -webkit-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}

.product_action_box li {
    -webkit-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    -o-transform: translate(0, 15px);
    transform: translate(0, 15px);
    opacity: 0;
}

.pr_action_btn li {
    position: relative;
    margin: 0px 5px;
    display: inline-block;
}

.product_box .pr_action_btn li a {
    border-radius: 100%;
    width: 45px;
    height: 45px;
    line-height: 45px;
}

.pr_action_btn li a {
    background-color: #fff;
    font-size: 0;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    width: 37px;
    height: 37px;
    padding: 0;
    display: block;
    text-align: center;
}

.pr_action_btn li a i {
    vertical-align: middle;
    line-height: 37px;
    font-size: 18px;
}

.product_box .product_info {
    position: relative;
}

.product_info {
    padding: 15px;
}

.product .product_title,
.product_box .product_title,
.product_wrap .product_title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
    padding-bottom: 6px;
}

.product_price {
    margin-bottom: 5px;
}

.product_rate {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    color: #F6BC3E;
}

.rating_num {
    font-size: 14px;
    margin-top: 6px;
    vertical-align: middle;
    display: inline-block;
}

.product_box .add-to-cart {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    background-color: #fff;
    padding: 10px 15px 15px 15px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
}

.btn-fill-out {
    background-color: transparent;
    border: 1px solid #FF324D;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.product:hover .product_img:before,
.product_box:hover .product_img:before {
    opacity: 1;
}

.product_box:hover .add-to-cart {
    bottom: 0px;
    opacity: 1;
    visibility: visible;
}

.product:hover .product_action_box li,
.product_box:hover .product_action_box li,
.product_wrap:hover .product_action_box li {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
}

.product_price del {
    font-size: 14px;
    margin-right: 5px;
    margin-left: 3px;
}

.product_price .price {
    color: #FF324D;
    font-weight: 600;
}
