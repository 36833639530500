
/*================================================
This file contains the following styles:


Default CSS
=================================================
 --Common Class Area start
 --Center Heading
 --Center Heading Two
 --Left Heading Three
 --Slider Arrow Style
 --Button
 --Common input style


=================================================*/


/*================================================
Default CSS
=================================================*/

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

html {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

.payment_method .collapsed {
    width: 100% !important;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-100 {
    margin-bottom: 100px;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    outline: 0 !important;
    color: var(---heading-color);
    cursor: pointer;
}

a:hover {
    text-decoration: none;
    color: var(--main-theme-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

h1 {
    font-size: 80px;
    font-weight: bold;
}

h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
}

h3 {
    font-size: 24px;
    font-weight: 500;
}

h4 {
    font-size: 22px;
    font-weight: 600;
}

h5 {
    font-size: 18px;
    font-weight: 400;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

p {
    font-size: 16px;
    line-height: 24px;
    color: var(--paragraph-color);
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
}

p:last-child {
    margin-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    padding: 0;
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

/* --Common Class Area start-- */

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1440px;
}

.img-zoom-hover img {
    /* transform-origin: 65% 75%; */
    transition: transform 1s, filter 0.5s ease-out;
}

.img-zoom-hover:hover img {
    transform: scale(1.1) rotate(1deg);
}

/* --Center Heading-- */
.center_heading {
    padding-bottom: 30px;
    text-align: center;
}

.center_heading h2 {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}

.center_heading p {
    padding-top: 10px;
}

/* --Center Heading Two-- */
.center_heading_two {
    padding-bottom: 30px;
    text-align: center;
}

.center_heading_two h2 {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}

.heading_border {
    display: block;
    background: var(--main-theme-color);
    height: 3px;
    width: 150px;
    margin: 10px auto 0 auto;
}

/* --Left Heading Three-- */
.left_heading_three {
    text-align: left;
}

.left_heading_three h2 {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}

/* --Slider Arrow Style--*/

.slider_arrows_one .owl-nav {
    top: -45px;
    position: absolute;
    right: 0;
}

.slider_arrows_one .owl-carousel .owl-nav button.owl-next,
.slider_arrows_one .owl-carousel .owl-nav button.owl-prev {
    background: var(--main-theme-color);
    color: #fff;
    border: none;
    padding: 0 !important;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    box-shadow: 0px 1px 3px -1px #000;
    transition: .4s all ease-in-out;
}

.slider_arrows_one .owl-carousel .owl-nav button.owl-next:hover,
.slider_arrows_one .owl-carousel .owl-nav button.owl-prev:hover {
    background: #fff;
    color: #000;
}


/* --Button-- */
.bg-gray {
    background: #f7f7f7;
}

.bg-whites {
    background-color: #fff;
    color: #000;
}

.bg-whites:hover {
    color: #fff;
    background-color: #000;
}


.bg-black {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}




.theme-btn-one {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    border-radius: 0;
}

.btn_md {
    padding: 16px 35px;
}

.btn_sm {
    padding: 10px 16px;
}

.btn-black-overlay {
    background-color: #000;
    color: #fff;
}

.btn-black-overlay:hover {
    background-color: #262626;
    color: #fff;
}


.common_banner_text {
    text-align: center;
    color: #fff;
}

.common_banner_text h2 {
    color: #fff;
}

.common_banner_text ul {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.common_banner_text ul li {
    color: #fff;
}

.common_banner_text ul li.active {
    color: var(--main-theme-color);
}

.common_banner_text ul li.slash {
    padding: 0 10px;

}

.common_banner_text ul li a {
    color: #fff;
}

.common_banner_text ul li a:hover {
    color: var(--main-theme-color);
}



/*--Common input style--*/

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="date"],
select {
    min-height: 45px;
    background: #fff;
    border-color: #ddd;
    border: 1px solid #ddd;
}

textarea {
    background: #fff !important;
    border-color: #ddd !important;
    border: 1px solid #ddd !important;
}

.form-control:focus {
    background-color: transparent;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    border-color: transparent;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
    background-color: #fff;
    outline: none;
    box-sizing: none;
    border: 1px solid var(--main-theme-color) !important;
}

button {
    border: none;
}
