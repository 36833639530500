
/*================================================
This file contains the following styles:


Form Elements
=================================================
 --Contact Page Area
 --Cookies area
 --Email Template One
 --Email Template Two
 --Email Template Three


=================================================*/


/* -------------------------
  Contact Page Area
---------------------------*/

.contact_info {
    padding-bottom: 50px;
}

.contact_info h3 {
    padding-bottom: 15px;
}

.left_side_contact ul li {
    padding-bottom: 30px;
}

.left_side_contact .address_location .contact_widget {
    display: flex;
}

.address_location i {
    padding-top: 4px;
    font-size: 18px;
}

.left_side_contact .address_location p {
    padding-left: 20px;
    color: #0a0d14;
}

.contact_widget a {
    display: block;
    padding-left: 20px;
    padding-bottom: 10px;
}

.contact_form_one form .form-group {
    margin-bottom: 30px;
}

.contact_form_one h3 {
    padding-bottom: 30px;
}

.map_area {
    margin-top: 50px;
}

.map_area iframe {
    width: 100%;
    border: none;
    height: 450px;
}

.contact_info_wrapper .map_area {
    margin-top: 0px;
}

#login_area .active {
    color: var(--main-theme-color);
}


/*--------------------------------
        Cookies area
-----------------------------------*/
.cookie-bar.show {
    bottom: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.cookie-bar p {
    color: #fff;
}

.cookie-bar a {
    margin-left: 20px;
}

.cookie-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    /* bottom: -70px; */
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: var(--main-theme-color);
    padding: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 99;
    border-top: 1px solid #fef5ef96;
}

/* ---Email Templates-- */
.tables_area {
    text-align: center;
    margin: 0 auto;
    width: 650px;
    display: block;
}

.box_table {
    box-shadow: 0px 0px 11px 5px #0000000f;
}

.tables_area ul {
    margin: 0;
    padding: 0;
}

.tables_area li {
    display: inline-block;
    text-decoration: unset;
}

.tables_area a {
    text-decoration: none;
}

.tables_area p {
    margin: 15px 0;
}

.tables_area h5 {
    color: #444;
    text-align: left;
    font-weight: 400;
    font-size: 13px;
}

.tables_area .text-center {
    text-align: center
}

.tables_area .main-bg-light {
    background-color: #fafafa;
}

.tables_area .title {
    color: #444444;
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
}

.tables_area table.top-0 {
    margin-top: 0;
}

.tables_area table.order-detail,
.tables_area .order-detail th,
.tables_area .order-detail td {
    border: 1px solid #ddd;
    border-collapse: collapse;
}

.tables_area .order-detail th {
    font-size: 16px;
    padding: 15px;
    text-align: center;
}

.tables_area .footer-social-icon tr td img {
    margin-left: 5px;
    margin-right: 5px;
}

/* ---Email Template One--- */
.email_template_one {
    text-align: center;
    margin: 0 auto;
    width: 650px;
    font-family: 'Open Sans', sans-serif;
    background-color: #e2e2e2;
    display: block;
}

.email_template_one ul {
    margin: 0;
    padding: 0;
}

.email_template_one li {
    display: inline-block;
    text-decoration: unset;
}

.email_template_one a {
    text-decoration: none;
}

.email_template_one h5 {
    margin: 10px;
    color: #777;
}

.email_template_one .text-center {
    text-align: center
}

.email_template_one .main-bg-light {
    background-color: #fafafa;
}

.email_template_one .title {
    color: #444444;
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-transform: capitalize;
    display: inline-block;
    line-height: 1;
}

.email_template_one .menu li a {
    text-transform: capitalize;
    color: #444;
    font-size: 16px;
    margin-right: 15px
}

.email_template_one .main-logo {
    width: 180px;
    padding: 10px 20px;
}

.email_template_one .product-box .product {
    /*border:1px solid #ddd;*/
    text-align: center;
    position: relative;
    margin: 0 15px;
}

.email_template_one .product-info {
    margin-top: 15px;
}

.email_template_one .product-info h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #777;
    margin-top: 0;
}

.email_template_one .product-info h4 {
    font-size: 16px;
    color: #444;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 5px;
    padding-bottom: 5px;
    line-height: 1;
}

.email_template_one .add-with-banner>td {
    padding: 0 15px;
}

.email_template_one .footer-social-icon tr td img {
    margin-left: 5px;
    margin-right: 5px;
}

/* ---Email Template Two---- */
.email_two_area {
    text-align: center;
    margin: 0 auto;
    width: 650px;
    font-family: 'Lato', sans-serif;
    background-color: #e2e2e2;
    display: block;
}

.email_two_area ul {
    margin: 0;
    padding: 0;
}

.email_two_area li {
    display: inline-block;
    text-decoration: unset;
}

.email_two_area a {
    text-decoration: none;
}

.email_two_area h5 {
    margin: 10px;
    color: #777;
}

.email_two_area .text-center {
    text-align: center
}

.email_two_area .main-bg-light {
    background-color: #fafafa;
}

.email_two_area .title {
    color: #444444;
    font-size: 22px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
}

.email_two_area .menu {
    width: 100%;
}

.email_two_area .menu li a {
    text-transform: capitalize;
    color: #444;
    font-size: 16px;
    margin-right: 15px
}

.email_two_area .main-logo {
    width: 180px;
    padding: 10px 20px;
    margin-bottom: -5px;
}

.email_two_area .product-box .product {
    text-align: center;
    position: relative;
}

.email_two_area .product-info {
    margin-top: 15px;
}

.email_two_area .product-info h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #777;
    margin-top: 0;
}

.email_two_area .product-info h4 {
    font-size: 16px;
    color: #444;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 5px;
    padding-bottom: 5px;
    line-height: 1;
}

.email_two_area .footer-social-icon tr td img {
    margin-left: 5px;
    margin-right: 5px;
}

/* ----Email Template Three---- */
.email_area_three {
    text-align: center;
    margin: 0 auto;
    width: 650px;
    font-family: 'Lato', sans-serif;
    background-color: #e2e2e2;
    display: block;
}

.email_area_three ul {
    margin: 0;
    padding: 0;
}

.email_area_three li {
    display: inline-block;
    text-decoration: unset;
}

.email_area_three a {
    text-decoration: none;
}

.email_area_three h5 {
    margin: 10px;
    color: #777;
}

.email_area_three .text-center {
    text-align: center
}

.email_area_three .main-bg-light {
    background-color: #fafafa;
}

.email_area_three .title {
    color: #444444;
    font-size: 22px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
}

.email_area_three .menu li a {
    text-transform: capitalize;
    color: #444;
    font-size: 16px;
    margin-right: 15px
}

.email_area_three .main-logo {
    width: 180px;
    padding: 10px 20px;
    margin-bottom: -5px;
}

.email_area_three .product-box .product {
    text-align: center;
    position: relative;
}

.email_area_three .product-info {
    margin-top: 15px;
}

.email_area_three .product-info h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 5px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #777;
    margin-top: 0;
}

.email_area_three .product-info h4 {
    font-size: 16px;
    color: #444;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 5px;
    padding-bottom: 5px;
    line-height: 1;
}

.email_area_three .footer-social-icon tr td img {
    margin-left: 5px;
    margin-right: 5px;
}

.back_btn {
    margin-bottom: 60px;
}

.back_btn a {
    font-size: 16px;
    color: var(--main-theme-color);
    font-weight: 500;
}

.back_btn a i {
    margin-right: 10px;
}

.contact_info_wrapper {
    background: #fff;
    box-shadow: 0 0 6px 5px rgb(0 50 85 / 6%);
    padding: 30px 25px;
}

.back_btn_emial {
    padding-top: 36px;
    padding-bottom: 30px;
    background: #fff;
    text-align: left;
}

.side_card ul li {
    background: #f7f7f7;
    margin-bottom: 30px;
    padding: 24px 20px;
    box-shadow: -3px 0px 0px 0px
}

#rating-star-furniture {
    display: inline-block;
    padding: 0px 10px 0 0px !important;
}

.after_login {
    padding-left: 26px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.after_login img {
    width: 22px;
    margin-right: 6px;
    height: 22px;
    border-radius: 50%;
    background: #f79837;
}

.after_login i {
    padding-left: 6px;
}

.custom_dropdown {
    display: block;
    height: auto;
    z-index: 999999;
    position: absolute;
    padding: 14px 17px;
    background: #FFF;
    box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
    transform: perspective(600px) rotateX(-90deg);
    transform-origin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    top: 33px;
    text-align: left;
    right: 0;
    width: max-content;
}

.after_login:hover .custom_dropdown {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateX(0deg);
}

.custom_dropdown li {
    padding-left: 0;
    padding-top: 7px;
}

.custom_dropdown li:first-child {
    padding-left: 0;
    padding-top: 0;
}

.heading_payment label {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 0;
    cursor: pointer;
}

.payment_body {
    padding: 6px 0px 9px 23px;
}

.payment_body img {
    cursor: pointer;
}

.payment_area_wrappers {
    margin-bottom: 10px;
}

.btn-main-search {
    color: #fff;
    background: var(--main-theme-color);
}

.go-top {
    position: fixed;
    cursor: pointer;
    right: 25px;
    color: #ffffff;
    background-color: var(--main-theme-color);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    transition: .9s;
    border-radius: 50%;
    top: 88%;
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
}

.go-top i {
    position: absolute;
    top: 43%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 50%;
}

.go-top:hover,
.go-top:focus {
    color: #ffffff;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%;
}

.loader_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: white;
}

#related_product .product_wrappers_one {
    margin-top: 0;
}

#vendor_area table {
    border: 1px solid #e9ecef;
}
