.product-detail-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .product-detail-container {
    flex-direction: column; /* Cambiar a columna en dispositivos móviles */
  }
}

.product-image-gallery {
  flex: 1;
}

.main-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
  border-radius: 8px;
}

.thumbnail-gallery {
  display: flex;
  flex-wrap: wrap; /* Permitir que las imágenes hagan wrap */
  gap: 10px;
  margin-top: 10px;
  overflow-y: hidden;
  padding-bottom: 10px;
}

.thumbnail-image {
  width: calc(20% - 10px); /* Ajustar el ancho para que se vean 5 imágenes por fila con espacio de 10px entre ellas */
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  object-fit: cover;
}

.thumbnail-image.active,
.thumbnail-image:hover {
  transform: scale(1.1);
}

.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product-info h1 {
  font-size: 24px;
  margin: 0;
}

.product-price {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}

.product-description {
  font-size: 16px;
  color: #666;
}

.product-colors,
.product-sizes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.size-button {
  padding: 10px 15px;
  margin: 5px;
  border: 1px solid #4d4d4d;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.size-button.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.size-button.disabled {
  color: #aaa;
  border-color: #aaa;
  text-decoration: line-through;
  cursor: not-allowed;
  background-color: #f9f9f9;
}

.size-button:not(.disabled):hover {
  background-color: #8f8f8f;
}

.color-button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
}

.color-button.selected,
.color-button:hover {
  transform: scale(1.2);
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.size-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.size-button.selected {
  background-color: black;
  color: white;
}

.size-button:hover {
  background-color: #333;
  color: white;
}

.add-to-cart-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.quantity-selector button {
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  transition: background-color 0.2s;
}

.quantity-selector button:hover {
  background-color: black;
  color: white;
}

.quantity-selector span {
  padding: 5px 10px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.add-to-cart-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-to-cart-button:hover {
  background-color: #333;
}

.product-category {
  font-size: 14px;
  color: #999;
}
