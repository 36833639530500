.my-account-container {
  padding: 20px;
}

.my-account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.my-account-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}
